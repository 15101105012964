import { DoughnutChart, EmptyTable, ProgressBar, StarRating } from "components";
import styles from "./styles.module.scss";
import { useEffect, useRef, useState } from "react";
import {
  ChevronRightIcon,
  CloseIcon,
  InfoIcon,
  TickIcon,
  emptyAssessmentsImg,
} from "assets";
import { MetricHeading } from "../components";

interface DomainKnowledgeData {
  question: string;
  answer: string;
  score?: string;
  rating: number;
  type: "open-ended" | "drop-down" | "checklist";
}
export interface DomainKnowledgeReportProps {
  domainKnowledge: ThematicAreaData[] | undefined;
  isFullReport: boolean;
  isDefault: boolean;
  isPublic: boolean;
  isAutoScored: boolean;
  handleAutoScore: () => void;
}

export interface ThematicAreaData {
  theme: string;
  description: string;
  scoreGotten: number;
  scoreObtainable: number;
  questions: {
    type: "open_ended" | "scored";
    openEnded?: OpenEndedQuestion;
    scored?: NonOpenEndedQuestion;
  }[];
}

interface OpenEndedQuestion {
  question: string;
  answer: string;
  reason?: string;
  score?: number;
  processed: boolean;
  theme?: string;
}

interface NonOpenEndedQuestion {
  question: string;
  scoreGotten: number;
  scoreObtainable: number;
  description: string;
  type: "drop-down" | "checklist";
  options: {
    value: string;
    isCorrect: boolean;
    candidateSelected: boolean;
  }[];
}

const DomainKnowledge: React.FC<DomainKnowledgeReportProps> = ({
  isFullReport,
  domainKnowledge,
  isDefault,
  isAutoScored,
  handleAutoScore,
  isPublic,
}) => {
  if (!domainKnowledge)
    return (
      <EmptyTable
        image={emptyAssessmentsImg}
        text="No response found for this metric"
      />
    );

  const defaultQ = domainKnowledge.map((item) => item.questions).flat();

  return (
    <>
      <section className={styles.wrapper}>
        {isFullReport ? <MetricHeading heading="Domain Knowledge" /> : ""}
        {!isPublic && isDefault && !isAutoScored && (
          <div role="button" onClick={handleAutoScore} className={styles.info}>
            <InfoIcon />
            <p>
              Skip the manual review by auto-scoring this candidate responses.
            </p>
          </div>
        )}
        <section className={styles.container}>
          {isDefault
            ? defaultQ.map(
                (question, index) =>
                  question.openEnded && (
                    <OpenEndedQuestion
                      count={index + 1}
                      {...question.openEnded}
                    />
                  )
              )
            : domainKnowledge.map((data, index) => (
                <QuestionGroup
                  key={`${data.theme}_scored_${index}`}
                  {...data}
                />
              ))}
          {/* {domainKnowledge.map((data, index) => (
            <QuestionGroup key={`${data.theme}_scored_${index}`} {...data} />
          ))} */}
        </section>
      </section>
    </>
  );
};

export interface OpenEndedResultProps extends OpenEndedQuestion {
  count: number;
}

const OpenEndedQuestion: React.FC<OpenEndedResultProps> = ({
  question,
  answer,
  reason,
  score,
  processed,
  count,
  theme,
}) => {
  return (
    <section className={styles.openEndedSec}>
      {theme ? <p className={styles.openEndedSec__ttl}>{theme}</p> : ""}
      <div className={styles.openEnded}>
        <div>
          <QuestionItem title={`Question ${count}`} content={question} />
          {processed ? (
            <QuestionItem
              title={`Candidates score: ${score}/10`}
              content={reason}
            />
          ) : (
            ""
          )}
          <QuestionItem title={"Candidates answer"} content={answer} />
        </div>
      </div>
    </section>
  );
};

const QuestionItem = ({ title, content }) => {
  const [show, setShow] = useState(true);

  return (
    <div className={styles.openEnded__info}>
      <div className={styles.openEnded__info__hd}>
        <p className={styles.openEnded__info__ttl}>{title}</p>
        <ChevronRightIcon
          className={show ? styles.rotate : ""}
          role="button"
          onClick={() => setShow((prev) => !prev)}
        />
      </div>
      {show ? <p className={styles.openEnded__info__txt}>{content}</p> : ""}
    </div>
  );
};

export interface ScoredResultProps extends NonOpenEndedQuestion {
  count: number;
}

const QuestionGroup: React.FC<ThematicAreaData> = ({
  theme,
  description,
  scoreGotten,
  scoreObtainable,
  questions,
}) => {
  const [show, setShow] = useState(false);

  const score = scoreObtainable
    ? Math.floor((scoreGotten / scoreObtainable) * 100)
    : 0;

  return (
    <section className={styles.scored}>
      <div className={styles.scored__score}>
        <div className={styles.scored__score__chart}>
          <DoughnutChart score={score} cutout="50%" />
          <span
            className={`${styles.scored__score__chart__score} ${
              score >= 90
                ? styles.green
                : score >= 70
                ? styles.blue
                : score >= 50
                ? styles.orange
                : styles.red
            }`}
          >
            {score}%
          </span>
        </div>
        <div className={styles.scored__score__info}>
          <p>{theme}</p>
          <p>
            {scoreGotten}/{scoreObtainable} points
          </p>
        </div>
        <button onClick={() => setShow((prev) => !prev)}>
          <span>{show ? "Hide" : "Show"} answers</span>{" "}
          <ChevronRightIcon className={show ? styles.rotate : ""} />
        </button>
      </div>
      {show && (
        <>
          {questions.map((question, index) => (
            <>
              {question.type === "open_ended" && question.openEnded ? (
                <OpenEndedQuestion count={index + 1} {...question.openEnded} />
              ) : question.scored ? (
                <ScoredQuestion count={index + 1} {...question.scored} />
              ) : (
                ""
              )}
            </>
          ))}
        </>
      )}
    </section>
  );
};

const ScoredQuestion: React.FC<ScoredResultProps> = ({
  count,
  scoreGotten,
  scoreObtainable,
  description,
  type,
  options,
  question,
}) => {
  return (
    <section className={styles.scored}>
      <div className={styles.scored__question}>
        <p
          style={{ marginBottom: "10px" }}
          className={styles.openEnded__info__ttl}
        >
          Question {count}
        </p>
        <p className={styles.scored__question__ttl}>
          {question}
          <span>
            <span>{scoreGotten}</span> / {scoreObtainable} points
          </span>
        </p>
        <p className={styles.scored__instruction}>{description}</p>
        <div
          className={`${styles.options} ${
            type === "drop-down" ? styles.radio : styles.check
          }`}
        >
          {options.map((option, idx) => (
            <p
              key={`question_${idx}`}
              className={`${option.candidateSelected ? styles.selected : ""} ${
                option.candidateSelected && option.isCorrect
                  ? styles.correct
                  : option.candidateSelected
                  ? styles.wrong
                  : ""
              }`}
            >
              {option.value}{" "}
              {option.isCorrect ? (
                <TickIcon className={styles.rightAnswer} />
              ) : (
                <CloseIcon className={styles.wrongAnswer} />
              )}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
};

export { DomainKnowledge };
