import {
  fetchDomainKnowledgeService,
  fetchDomainKnowledgeUnauthService,
  fetchReportService,
  fetchReportUnauthService,
  moveReportRequestData,
  moveReportService,
} from "api";
import {
  ComparisonData,
  ConfirmationModal,
  Loader,
  OptionType,
} from "components";
import { ReportUI } from "features";
import { BasicExpectationResultData } from "features/reports/report/basicExpectations";
import { ThematicAreaData } from "features/reports/report/domainKnowledge";
import { Candidate } from "features/reports/report/header";
import { PersonalityResultData } from "features/reports/report/personality";
import { MotivatorsResultData } from "features/reports/report/workMotivators";
import { WorkQualitiesData } from "features/reports/report/workQualities";
import { WorkStyleResultData } from "features/reports/report/workStyle";
import { useApiRequest, useToast } from "hooks";
import { Navbar } from "layout";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "router";
import {
  bucketOptions,
  ExperienceLevelOptions,
  ExperienceYearOptions,
} from "utils/options";

interface ReportData {
  metrics: string[];
  candidate: Candidate | undefined;
  basicExpectation:
    | { data: BasicExpectationResultData; score: number }
    | undefined;
  softSkills: { data: ComparisonData[]; score: number } | undefined;
  workStyle: { data: WorkStyleResultData; score: number } | undefined;
  workQualities:
    | {
        data: WorkQualitiesData[];
        score: number;
        expectation: { title: string; isMatch: boolean }[];
      }
    | undefined;
  workMotivators:
    | {
        data: MotivatorsResultData[];
        score: number;
      }
    | undefined;
  personality: { data: PersonalityResultData; score: number } | undefined;
  domainKnowledge: ThematicAreaData[] | undefined;
  isAutoScored: boolean;
}

interface Props {
  isFullReport?: boolean;
  handleBack?: () => void;
  isPublic?: boolean;
}

const Report: React.FC<Props> = ({
  isFullReport = false,
  handleBack,
  isPublic,
}) => {
  const [movePrompt, setMovePrompt] = useState<{
    show: boolean;
    data: moveReportRequestData | undefined;
  }>({
    show: false,
    data: undefined,
  });

  // Hooks
  const navigate = useNavigate();
  const { id } = useParams();
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});
  const {
    run: runMove,
    data: moveResponse,
    requestStatus: moveStatus,
    error: moveError,
  } = useApiRequest({});
  const {
    run: runDK,
    data: dkResponse,
    requestStatus: dkStatus,
    error: dkError,
  } = useApiRequest({});

  const fetchReport = () => {
    id && run(fetchReportService(id));
  };

  const fetchReportUnauth = () => {
    id && run(fetchReportUnauthService(id));
  };

  const assessmentId = response?.data?.data?.assessment_summary?.id;
  const assessmentTitle = response?.data?.data?.assessment_summary?.title;

  const fetchDomainKnowledge = () =>
    assessmentId && runDK(fetchDomainKnowledgeUnauthService(assessmentId));

  useEffect(() => {
    isPublic ? fetchReportUnauth() : fetchReport();
  }, []);

  useEffect(() => {
    fetchDomainKnowledge();
  }, [assessmentId]);

  const sortResultsByMatch = (results) => {
    // Sorting function to prioritize items with 'match' as true
    const compareFunction = (a, b) => {
      // Sort true matches before false matches
      if (a.match && !b.match) {
        return -1;
      } else if (!a.match && b.match) {
        return 1;
      } else {
        return 0; // Keep the original order if both have the same match value
      }
    };

    // Sort the results array using the compare function
    return results.sort(compareFunction);
  };

  const computeWorkMotivators = ({ motivators, candidate }) => {
    // Create a set of remaining motivators to track which ones haven't been used yet
    let remainingMotivators = new Set(motivators);

    const candidate2 = sortResultsByMatch(candidate).map((item) => ({
      match: item.match,
      ...item.details,
    }));

    // Map the objectsArray to a new array with the 'expectation' property
    const newArray: MotivatorsResultData[] = candidate2.map((obj) => {
      // Check if the 'title' matches any motivator
      const matchIndex = motivators.indexOf(obj.title);

      // If there's a match, use the matched value; otherwise, use the first remaining motivator
      const expectation =
        matchIndex !== -1
          ? motivators[matchIndex]
          : remainingMotivators.values().next().value;

      // Remove the used motivator from the set
      if (matchIndex !== -1) {
        remainingMotivators.delete(obj.title);
      } else {
        remainingMotivators.delete(expectation);
      }

      // Return a new object with the existing properties and the new 'expectation' property
      return { ...obj, expectation };
    });

    return newArray;
  };

  const calculateLevel = ({
    options,
    value,
    expectation,
  }: {
    options: OptionType[];
    value: string;
    expectation: string;
  }) => {
    const valueLevel = options.findIndex((item) => item.value === value);
    const expectationLevel = options.findIndex(
      (item) => item.value === expectation
    );

    return valueLevel >= expectationLevel;
  };

  const isDefault = useMemo<boolean>(() => {
    if (dkResponse?.status === 200) {
      return !dkResponse.data.data.use_custom;
    }
    return false;
  }, [dkResponse, dkError]);

  const report = useMemo<ReportData>(() => {
    if (response?.status === 200) {
      const data = response.data.data;
      const candidate = data.response_details;
      const basicExpectation = data.basic_expectation;
      const workStyle = data.work_style;
      const softSkill = data.soft_skills;
      const workQualities = data.work_place_qualities;
      const workMotivators = data.work_motivator;
      const personality = data.personality;
      const metrics = data.assessment_summary.metrics_set;
      const domainKnowledge = data.domain_knowledge;

      const hasBasicExpectation = metrics.find(
        (item) => item === "basic_expectations"
      );
      const hasSoftSkill = metrics.find((item) => item === "soft_skill");
      const hasWorkCulture = metrics.find((item) => item === "work_culture");
      const hasPersonality = metrics.find((item) => item === "personality");
      const hasDK = metrics.find((item) => item === "domain_knowledge");

      return {
        metrics,
        candidate: {
          score: parseInt(candidate.total_score),
          name: candidate.full_name,
          email: candidate.email,
          number: candidate.phone_number,
          resume: candidate.resume,
          portfolio: candidate.portfolio,
          bucket: candidate.bucket,
          metrics: {
            basicExpectation: hasBasicExpectation
              ? basicExpectation?.score ?? null
              : undefined,
            softSkills: hasSoftSkill ? softSkill?.score ?? null : undefined,
            workStyle: hasWorkCulture ? workStyle?.score ?? null : undefined,
            personality: hasPersonality
              ? personality?.score ?? null
              : undefined,
            workQualities: hasWorkCulture
              ? workQualities?.score ?? null
              : undefined,
            workMotivators: hasWorkCulture
              ? workMotivators?.score ?? null
              : undefined,
            domainKnowledge: hasDK ? domainKnowledge?.score ?? null : undefined,
          },
        },
        basicExpectation:
          hasBasicExpectation && basicExpectation
            ? {
                score: basicExpectation.score,
                data: {
                  experienceLevel: {
                    employer:
                      basicExpectation.employer_expectation.experience_level,
                    candidate:
                      basicExpectation.candidate_response.experience_level,
                    isMatch: calculateLevel({
                      options: ExperienceLevelOptions,
                      value:
                        basicExpectation.candidate_response.experience_level,
                      expectation:
                        basicExpectation.employer_expectation.experience_level,
                    }),
                    // basicExpectation.employer_expectation.experience_level ===
                    // basicExpectation.candidate_response.experience_level,
                  },
                  experienceYears: {
                    employer:
                      basicExpectation.employer_expectation.years_of_experience,
                    candidate:
                      basicExpectation.candidate_response.years_of_experience,
                    isMatch: calculateLevel({
                      options: ExperienceYearOptions,

                      value:
                        basicExpectation.candidate_response.years_of_experience,
                      expectation:
                        basicExpectation.employer_expectation
                          .years_of_experience,
                    }),
                    // basicExpectation.employer_expectation
                    //   .years_of_experience ===
                    // basicExpectation.candidate_response.years_of_experience,
                  },
                  employmentPref: {
                    employer:
                      basicExpectation.employer_expectation.employment_type,
                    candidate:
                      basicExpectation.candidate_response.employment_type,
                    isMatch:
                      basicExpectation.employer_expectation.employment_type ===
                      basicExpectation.candidate_response.employment_type,
                  },
                  salary: {
                    employer: `${basicExpectation.employer_expectation.salary_range_currency} ${basicExpectation.employer_expectation.min_salary_range_amount} - ${basicExpectation.employer_expectation.max_salary_range_amount}`,
                    candidate: `${basicExpectation.employer_expectation.salary_range_currency} ${basicExpectation.candidate_response.salary_expectation}`,
                    isMatch:
                      parseFloat(
                        basicExpectation.candidate_response.salary_expectation
                      ) <=
                      parseFloat(
                        basicExpectation.employer_expectation
                          .max_salary_range_amount
                      ),

                    // parseFloat(
                    //   basicExpectation.candidate_response.salary_expectation
                    // ) >=
                    //   parseFloat(
                    //     basicExpectation.employer_expectation
                    //       .min_salary_range_amount
                    //   ) &&
                    // parseFloat(
                    //   basicExpectation.candidate_response.salary_expectation
                    // ) <=
                    //   parseFloat(
                    //     basicExpectation.employer_expectation
                    //       .max_salary_range_amount
                    //   ),
                  },
                  experienceEnv: {
                    employer:
                      basicExpectation.employer_expectation
                        .experience_environment,
                    candidate:
                      basicExpectation.candidate_response
                        .experience_environment,
                    isMatch:
                      basicExpectation.employer_expectation
                        .experience_environment ===
                      basicExpectation.candidate_response
                        .experience_environment,
                  },
                },
              }
            : undefined,
        softSkills:
          hasSoftSkill && softSkill
            ? {
                score: softSkill.score,
                data: softSkill.soft_skills.map((item) => ({
                  title: item.name.replaceAll("_", " "),
                  expectation: item.score.expectation,
                  expectationStr: `${item.score.expectation}`,
                  scale: item.score.expectation,
                  result: item.score.candidate_result,
                  description: (
                    <>
                      <b>{item.score.description.title}: </b>
                      {item.score.description.description}
                    </>
                  ),
                })),
              }
            : undefined,
        workStyle:
          hasWorkCulture && workStyle
            ? {
                score: workStyle.score,
                data: {
                  prioritizing: {
                    expectation: workStyle.prioritizing.expectation,
                    result: workStyle.prioritizing.candidate_result,
                    keyQuality: workStyle.prioritizing.description,
                    goodAt: workStyle.prioritizing.good_at,
                    poorAt: workStyle.prioritizing.poor_at,
                  },
                  planning: {
                    expectation: workStyle.planning.expectation,
                    result: workStyle.planning.candidate_result,
                    keyQuality: workStyle.planning.description,
                    goodAt: workStyle.planning.good_at,
                    poorAt: workStyle.planning.poor_at,
                  },
                  visualizing: {
                    expectation: workStyle.visualizing.expectation,
                    result: workStyle.visualizing.candidate_result,
                    keyQuality: workStyle.visualizing.description,
                    goodAt: workStyle.visualizing.good_at,
                    poorAt: workStyle.visualizing.poor_at,
                  },
                  coordinating: {
                    expectation: workStyle.coorddinating.expectation,
                    result: workStyle.coorddinating.candidate_result,
                    keyQuality: workStyle.coorddinating.description,
                    goodAt: workStyle.coorddinating.good_at,
                    poorAt: workStyle.coorddinating.poor_at,
                  },
                },
              }
            : undefined,
        workQualities:
          hasWorkCulture && workQualities
            ? {
                score: workQualities.score,
                data: workQualities.qualities.map((quality) => ({
                  title: quality.title,
                  match: quality.match,
                  characteristics: quality.characteristics,
                  behaviors: quality.behaviours,
                })),
                expectation: workQualities.employer_expectation.map((item) => ({
                  title: item.title,
                  isMatch: item.match,
                })),
              }
            : undefined,
        workMotivators:
          hasWorkCulture && workMotivators
            ? {
                score: workMotivators.score,
                data: computeWorkMotivators({
                  motivators: workMotivators.expectations,
                  candidate: workMotivators.candidate_result,
                }),
              }
            : undefined,
        personality:
          hasPersonality && personality
            ? {
                score: personality.score,
                data: {
                  data: personality.core_areas.map((item) => ({
                    title: item.title,
                    rating: item.top_boundary.score,
                    label1: item.top_boundary.name,
                    label2: item.bottom_boundary.name,
                  })),
                  info: {
                    strengths: personality.strengths,
                    growAreas: personality.growth_areas,
                    title: personality.personality_type,
                  },
                  expectation: {
                    strengths: personality.employer_expectations[0].strengths,
                    growAreas:
                      personality.employer_expectations[0].growth_areas,
                    title:
                      personality.employer_expectations[0].personality_type,
                  },
                },
              }
            : undefined,
        domainKnowledge:
          hasDK && domainKnowledge
            ? domainKnowledge.results.map((questionGroup) => ({
                theme: questionGroup.title,
                description: questionGroup.description,
                scoreGotten: questionGroup.score_gotten,
                scoreObtainable: questionGroup.score_obtainable,
                questions: questionGroup.question_answers_pair.map(
                  (question) => ({
                    type:
                      question.type === "open_ended" ? "open_ended" : "scored",
                    openEnded:
                      question.type === "open_ended"
                        ? {
                            theme: questionGroup.title,
                            question: question.question,
                            answer: question.answer,
                            score: question.score,
                            reason: question.reason,
                            processed: question.processed,
                          }
                        : undefined,
                    scored:
                      question.type !== "open_ended"
                        ? {
                            question: question.question.question_content,
                            scoreGotten: question.score,
                            scoreObtainable:
                              question.question.points_obtainable,
                            description: question.question.description,
                            type:
                              question.question.question_type ===
                              "single_choice"
                                ? "drop-down"
                                : "checklist",
                            options: question.options.map((option) => ({
                              value: option.content,
                              isCorrect: option.correct,
                              candidateSelected: option.is_candidate_selection,
                            })),
                          }
                        : undefined,
                  })
                ),
              }))
            : undefined,
        isAutoScored: hasDK && domainKnowledge?.auto_scored,
      };
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Unable to fetch report, please refresh the page or try again later",
        type: "error",
        timeOut: 5000,
      });
    }

    return {
      metrics: [],
      candidate: undefined,
      basicExpectation: undefined,
      softSkills: undefined,
      workStyle: undefined,
      workQualities: undefined,
      workMotivators: undefined,
      personality: undefined,
      domainKnowledge: [],
      isAutoScored: false,
    };
  }, [response, error]);

  const handleFullReport = () => {
    navigate(Routes.employerReportFull(id));
  };

  const handleMoveReports = (data: moveReportRequestData) => {
    runMove(moveReportService(data));
  };

  useMemo(() => {
    if (moveResponse?.status === 200) {
      fetchReport();
    } else if (moveError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          moveError?.response?.data?.error?.message ??
          "Unable to move candidate, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [moveResponse, moveError]);

  const handleAutoScore = () => {
    navigate(Routes.employerReportRoleID(assessmentId), {
      state: {
        autoScore: true,
      },
    });
  };

  const showLoader = requestStatus.isPending || moveStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <ConfirmationModal
        show={movePrompt.show}
        close={() => setMovePrompt({ show: false, data: undefined })}
        handleContinue={() =>
          movePrompt.data && handleMoveReports(movePrompt.data)
        }
        title={"Move Candidate"}
        text={`Are you sure you want to move this candidate to the ${
          bucketOptions.find(
            (item) => item.value === movePrompt?.data?.new_bucket
          )?.label
        } bucket?`}
      />
      {isFullReport ? "" : <Navbar title={"Track & View Reports"} />}
      {id && report.candidate && (
        <ReportUI
          metrics={report.metrics}
          isPublic={isPublic ?? false}
          candidate={{
            isFullReport: isFullReport,
            handleView: handleBack ?? handleFullReport,
            candidate: report.candidate,
            isPublic: isPublic ?? false,
            isDefault: isDefault,
            isAutoScored: report.isAutoScored,
            role: {
              id: id,
              title: assessmentTitle.toLowerCase().replaceAll(" ", "-"),
            },
          }}
          basicExpectation={{
            isFullReport: isFullReport,
            result: report.basicExpectation?.data,
            score: report.basicExpectation?.score,
          }}
          softSkill={{
            isFullReport: isFullReport,
            skills: report.softSkills?.data,
            score: report.softSkills?.score,
          }}
          workStyle={{
            isFullReport: isFullReport,
            workStyle: report.workStyle?.data,
            score: report.workStyle?.score,
          }}
          workQualities={{
            isFullReport: isFullReport,
            workQualities: report.workQualities?.data,
            score: report.workQualities?.score,
            expectation: report.workQualities?.expectation ?? [],
          }}
          workMotivators={{
            isFullReport: isFullReport,
            workMotivators: report.workMotivators?.data,
            score: report.workMotivators?.score,
          }}
          personality={{
            isFullReport: isFullReport,
            personality: report.personality?.data,
            score: report.personality?.score,
          }}
          domainKnowledge={{
            isFullReport: isFullReport,
            domainKnowledge: report.domainKnowledge,
            isDefault: isDefault,
            isAutoScored: report.isAutoScored,
            handleAutoScore,
            isPublic: isPublic ?? false,
          }}
          role={assessmentTitle ?? ""}
          roleId={assessmentId ?? ""}
          id={id}
          handleMove={(data) => setMovePrompt({ show: true, data })}
        />
      )}
    </>
  );
};

export { Report };
