import { fetchPlanService, fetchUserService } from "api";
import { getDaysBetween } from "helpers";
import { useApiRequest, useToast } from "hooks";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";
import { updatePlan, updateUser } from "state";
import { useAppDispatch } from "state/hooks";

const useFetchPlan = () => {
  // Hooks
  const { openToast } = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const getPlan = () => run(fetchPlanService());

  useMemo(() => {
    if (requestStatus.isPending || requestStatus.isIdle) return;
    if (response?.status === 200) {
      const plan = response.data.data;

      if (plan.status !== "active" || plan.has_expired)
        navigate(Routes.billings);

      dispatch(
        updatePlan({
          billingCycle: plan.billing_period,
          plan: plan.plan_type,
          roles: {
            total: plan.total_roles_allowed,
            used: plan.total_roles_used,
          },
          candidates: {
            total: plan.total_candidates_allowed,
            used: plan.total_candidates_used,
          },
          autoScoring: {
            balance: plan.auto_scoring_credit_balance?.credit_balance ?? 0,
            used: plan.auto_scoring_credit_balance?.credit_used ?? 0,
          },
          status: plan.has_expired ? "expired" : plan.status,
          amount: `$${plan.amount_paid}`,
          daysLeft: getDaysBetween(plan.next_due_date).days,
        })
      );
    }
  }, [response, error]);

  return {
    getPlan,
    loading: requestStatus.isPending,
  };
};

export { useFetchPlan };
