import { CloseIcon, LogoWithText } from "assets";
import styles from "./styles.module.scss";
import { getDaysBetween, getTimeDifference } from "helpers";
import Countdown from "react-countdown";
import { useEffect, useState } from "react";
import { Support } from "layout/support";

interface Props {
  children: any;
  isOverview?: boolean;
  length: number;
  isLive: boolean;
  startDate: string;
  duration: number;
  employer: { logo: string; name: string };
  handleTimeout: () => void;
  startTimer: boolean;
}

const CandidateAssessment: React.FC<Props> = ({
  children,
  isOverview,
  isLive,
  length,
  startDate,
  duration,
  employer,
  handleTimeout,
  startTimer,
}) => {
  const [startDateInfo, setStartDateInfo] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    isFutureDate: true,
  });

  const answered = parseInt(localStorage.getItem("answered") ?? "0");
  const { days, hours, minutes, seconds, isFutureDate } = startDateInfo;

  const formatTime = (time) => String(time).padStart(2, "0");
  const formattedTime =
    isFutureDate && startDate
      ? days > 1
        ? formatTime(days)
        : hours > 1
        ? formatTime(hours)
        : formatTime(minutes)
      : "--";

  const unit =
    days > 1
      ? "days"
      : hours > 1
      ? "hours"
      : minutes > 1 || minutes === 0
      ? "minutes"
      : "minute";

  const secondsLeft = localStorage.getItem("secondsLeft");
  const timeLeft =
    secondsLeft && duration ? parseFloat(secondsLeft) / 60 : duration;

  const now = new Date();
  now.setMinutes(now.getMinutes() + timeLeft); // timestamp

  useEffect(() => {
    const timer = setInterval(() => {
      const timeInfo = getTimeDifference(startDate);
      !timeInfo.isFutureDate && clearInterval(timer);
      setStartDateInfo(timeInfo);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const [timeWarning, setTimeWarning] = useState({ show: false, auto: true });
  console.log(duration, startTimer);
  return (
    <>
      {!isLive && (
        <aside className={styles.scheduled}>
          <section>
            {/* <CloseIcon className={styles.close} role="button" /> */}
            <p className={styles.txt1}>
              The assessment is not yet live. <br /> Please check back in
            </p>
            <div className={styles.days}>
              <span>{formattedTime.split("")[0]}</span>
              <span>{formattedTime.split("")[1]}</span>
            </div>
            <p className={styles.txt2}>{unit}</p>
          </section>
        </aside>
      )}
      <main className={`${styles.main} ${!isOverview ? styles.main__pad:""}`}>
        <header className={styles.header}>
          <section className={styles.container}>
            {employer.logo === "" ? (
              <h1>{employer.name}</h1>
            ) : (
              <div className={styles.logoSec}>
                <img
                  className={styles.logo}
                  src={employer.logo}
                  alt="employer logo"
                />
                <h1>{employer.name}</h1>
              </div>
            )}

            {duration && startTimer && !isOverview && (
              <CountDown date={now} handleTimeout={handleTimeout} />
            )}
          </section>
        </header>
        {!isOverview && (
          <section className={`${styles.info}`}>
            <div className={`${styles.container}`}>
              <div className={styles.progressWrap}>
                <div className={styles.progress}>
                  <div
                    style={{
                      width: `${(answered / length) * 100}%`,
                    }}
                    className={styles.progress__bar}
                  ></div>
                  <div className={styles.progress__bar__white}></div>
                </div>
                <p>Your progress</p>
              </div>
              <p className={styles.count}>
                {answered} of {length}
              </p>
            </div>
          </section>
        )}
        <section
          className={`${styles.container} ${styles.body} ${
            isOverview ? styles.overview : ""
          }`}
        >
          {children}
        </section>
        <footer className={`${styles.footer} ${styles.container}`}>
          <span>Enabled by</span> <LogoWithText />{" "}
        </footer>
      </main>
    </>
  );
};

export { CandidateAssessment };

const CountDown = ({ date, handleTimeout }) => {
  const [expired, setExpired] = useState(false);
  if (expired) return null;

  // Set the date we're counting down to
  let countDownDate = new Date(date).getTime();

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const secondsLeft = hours * 60 * 60 + minutes * 60 + seconds;

    localStorage.setItem("secondsLeft", secondsLeft);
    if (completed) {
      // Render a completed state
      setExpired(true);
      // go to completion screen
      handleTimeout();
      return null;
    } else {
      // Render a countdown
      return (
        <div className={styles.timer}>
          {hours ? (
            <div>
              <span>{hours}</span>
              <span>hours</span>
            </div>
          ) : null}
          <div>
            <span>{minutes}</span>
            <span>mins</span>
          </div>
          <div>
            <span>{seconds}</span>
            <span>secs</span>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <Countdown date={countDownDate} renderer={renderer} />
    </>
  );
};
