import { Comparisons, EmptyTable, RatingBar, ScoreBar } from "components";
import styles from "./styles.module.scss";
import { emptyAssessmentsImg } from "assets";
import {
  MetricOverview,
  MetricExpectations,
  MetricHeading,
} from "../components";
import { useEffect, useState } from "react";

interface PersonalityCoreData {
  title: string;
  rating: number;
  label1: string;
  label2: string;
}

interface PersonalityData {
  title: string;
  strengths: { title: string; content: string }[];
  growAreas: { title: string; content: string }[];
}

export interface PersonalityResultData {
  data: PersonalityCoreData[];
  info: PersonalityData;
  expectation: PersonalityData;
}

export interface PersonalityProps {
  score: number | undefined;
  personality: PersonalityResultData | undefined;
  isFullReport: boolean;
}

const Personality: React.FC<PersonalityProps> = ({
  isFullReport,
  score,
  personality,
}) => {
  const [active, setActive] = useState<PersonalityData>();

  useEffect(() => {
    setActive(personality?.info);
  }, [personality]);

  if (!personality || score === undefined)
    return (
      <EmptyTable
        image={emptyAssessmentsImg}
        text="No response found for this metric"
      />
    );

  return (
    <>
      <section className={styles.wrapper}>
        {isFullReport ? <MetricHeading heading="Personality" /> : ""}
        <section className={styles.container}>
          <div className={styles.sec1}>
            <MetricOverview
              description={
                "Gain insights into individual character traits and their impact on various aspects of work."
              }
              score={score}
            />
            <div className={styles.sec1__legend}>
              <div
                role="button"
                style={{ marginBottom: "1.5rem" }}
                className={styles.sec1__expectation}
                onClick={() => setActive(personality.expectation)}
              >
                <p>Expected personality type:</p>
                <MetricExpectations
                  data={[{ title: personality.expectation.title }]}
                />
              </div>
              <div
                role="button"
                onClick={() => setActive(personality.info)}
                className={styles.sec1__expectation}
              >
                <p>Candidates's personality type:</p>
                <MetricExpectations
                  data={[{ title: personality.info.title }]}
                />
              </div>
            </div>
          </div>
          <Comparisons
            data={personality.data.map((item) => ({
              leftLabel: item.label1,
              rightLabel: item.label2,
              result: item.rating,
              title: item.title,
              expectationStr: `${100 - item.rating}%`,
              scale: 100,
            }))}
          />
        </section>
        <div className={styles.mobile__legend}>
          <div
            role="button"
            style={{ marginBottom: "1.5rem" }}
            className={styles.sec1__expectation}
            onClick={() => setActive(personality.expectation)}
          >
            <p>Expected personality type:</p>
            <MetricExpectations
              data={[{ title: personality.expectation.title }]}
            />
          </div>
          <div
            role="button"
            onClick={() => setActive(personality.info)}
            className={styles.sec1__expectation}
          >
            <p>Candidates's personality type:</p>
            <MetricExpectations data={[{ title: personality.info.title }]} />
          </div>
        </div>
        {active ? (
          <div className={styles.details}>
            <p className={styles.details__ttl}>{active.title}</p>
            <div>
              <ol>
                <p>Strengths</p>
                {active.strengths.map((strength) => (
                  <li>
                    <b>{strength.title}:</b>
                    {strength.content}
                  </li>
                ))}
              </ol>
              <ol>
                <p>Growth Areas</p>
                {active.growAreas.map((strength) => (
                  <li>
                    <b>{strength.title}:</b>
                    {strength.content}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export { Personality };
