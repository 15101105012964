import { extendDeadlineService } from "api";
import { ExtendDeadlineModal, ExtendFormData, Loader } from "components";
import { getError } from "helpers";
import { useApiRequest, useToast } from "hooks";
import { useMemo } from "react";
import timezones from "timezones-list";

interface Props {
  show: boolean;
  close: () => void;
  id: string;
  callback: () => void;
  type: string;
  startDate: Date
}

const ExtendDeadline: React.FC<Props> = ({
  show,
  close,
  id,
  callback,
  type,
  startDate
}) => {
  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});
  const { openToast } = useToast();
  const getTimeZone = (zone) =>
    timezones.find((item) => item.label === zone)?.utc ?? "";

  const handleExtend = (data: ExtendFormData) => {
    run(
      extendDeadlineService({
        assessment_id: parseInt(id),
        end_date: `${data.end.date}T${data.end.time}${getTimeZone(
          data.timeZone.value
        )}`,
      })
    );
  };

  useMemo(() => {
    if (response?.status === 200) {
      callback();
      setTimeout(() => {
        close();
      }, 1000);
      openToast({
        show: true,
        heading:
          response?.data.message ??
          "Successfully extended the assessment deadline",
        text: "",
        type: "success",
        timeOut: 2000,
      });
      close();
    } else if (error) {
      openToast({
        show: true,
        ...getError({
          error: error,
          text: "Failed to send extend deadline, please try again later",
          heading: "Sorry",
        }),
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <ExtendDeadlineModal
        type={type}
        show={show}
        close={close}
        submit={handleExtend}
        startDate={startDate}
      />
    </>
  );
};

export { ExtendDeadline };
