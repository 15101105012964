import styles from "./styles.module.scss";
import { Button, Input } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { Routes } from "router";
import { useState } from "react";

interface SigninData {
  email: string;
  password: string;
}

const initSignup: SigninData = {
  email: "",
  password: "",
};

const schema = yup.object({
  email: yup.string().email("Enter a valid email").required("Required"),
  password: yup.string().required("Required"),
});

interface SigninProps {
  handleForgot: () => void;
  sumbit: (data: SigninData) => void;
}

const SigninUI: React.FC<SigninProps> = ({ handleForgot, sumbit }) => {
  const [remember, setRemember] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<SigninData>({
    resolver: yupResolver(schema),
    defaultValues: initSignup,
  });

  const onSubmit: SubmitHandler<SigninData> = (data) => {
    sumbit({ ...data, email: data.email.toLowerCase().trim() });
  };

  const handleRemember = () => {
    setRemember((prev) => !prev);
    localStorage.setItem("precisionRememberMe", "true");
  };

  return (
    <>
      <h1 className={styles.ttl}>Welcome Back</h1>
      <p className={styles.signin}>
        New to Precision? <Link to={Routes.signup}>Sign up</Link>
      </p>
      <form className={styles.form}>
        <Input
          label="Work email"
          placeholder="Enter your work or business email address"
          type="email"
          required
          validatorMessage={errors.email?.message}
          name="email"
          register={register}
          value={watch("email")}
        />
        <Input
          label="Password"
          placeholder="Enter password"
          type="password"
          required
          validatorMessage={errors.password?.message}
          name="password"
          register={register}
          value={watch("password")}
        />
        <div className={styles.otherInfo}>
          <label>
            <input
              onChange={handleRemember}
              checked={remember}
              type="checkbox"
            />
            Remember me
          </label>
          <Link to={Routes.forgotPassword}>Forgot Password?</Link>
        </div>
        <Button
          className={styles.btn}
          type="fill"
          onClick={handleSubmit(onSubmit)}
        >
          Sign in
        </Button>
      </form>
    </>
  );
};

export { SigninUI };
