import { OnboardingModalUI } from "features";
import { queryObject } from "helpers";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "state/hooks";
import {
  useApiRequest,
  useFetchCountries,
  useFetchUser,
  useToast,
} from "hooks";
import { createCompanyService, updateUserService } from "api";
import { Loader } from "components";
import { updateUser } from "state";
import { howOptions } from "features/registration/onboarding/utils";

const OnboardingModal = () => {
  // States
  const [stage, setStage] = useState(1);
  const { search } = useLocation();
  const show: boolean = queryObject(search).onboarding;
  const [searchParams, setSearchParams] = useSearchParams();

  const removeOnboardingParam = () => {
    if (searchParams.has("onboarding") && searchParams.has("incomplete")) {
      searchParams.delete("onboarding");
      searchParams.delete("incomplete");
      setSearchParams(searchParams);
    }
  };

  // Hooks
  const user = useAppSelector((state) => state.user);
  const {
    completedBusiness,
    completedProfile,
    firstName,
    lastName,
    role,
    phone,
    referral,
    country,
  } = user;
  const { openToast } = useToast();
  const dispatch = useAppDispatch();
  const { getUser } = useFetchUser();
  const {
    fetchCountries,
    countries,
    loading: loadingCountries,
  } = useFetchCountries();

  // API Request Hooks
  const {
    run: runUpdateUser,
    data: updateUserResponse,
    requestStatus: updateUserStatus,
    error: updateUserError,
  } = useApiRequest({});
  const {
    run: runCreateCompany,
    data: createCompanyResponse,
    requestStatus: createCompanyStatus,
    error: createCompanyError,
  } = useApiRequest({});

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    setStage(searchParams.get("incomplete") === "business" ? 2 : 1);
  }, [searchParams]);

  const handleUpdateUser = (data) => {
    runUpdateUser(updateUserService(data));
  };

  const handleCreateCompany = (data) => {
    runCreateCompany(createCompanyService(data));
  };

  useMemo(() => {
    if (updateUserResponse?.status === 200) {
      setStage(2);
      dispatch(
        updateUser({
          ...user,
          completedProfile: true,
        })
      );
      getUser();
      openToast({
        show: true,
        heading: "Profile update successful!",
        text: "",
        type: "success",
        timeOut: 2000,
      });
    } else if (updateUserError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          updateUserError?.response?.data?.error?.message ??
          "Failed to update your personal profile, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [updateUserResponse, updateUserError]);

  useMemo(() => {
    if (createCompanyResponse?.status === 200) {
      getUser();
      dispatch(
        updateUser({
          ...user,
          completedBusiness: true,
        })
      );
      removeOnboardingParam();
      openToast({
        show: true,
        heading:
          createCompanyResponse?.data?.message ?? "Company profile created!",
        text: "",
        type: "success",
        timeOut: 2000,
      });
    } else if (createCompanyError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          createCompanyError?.response?.data?.error?.message ??
          "Failed to create your company profile, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [createCompanyResponse, createCompanyError]);

  const showLoader =
    updateUserStatus.isPending ||
    createCompanyStatus.isPending ||
    loadingCountries;

  return (
    <>
      <Loader loading={showLoader} />
      <OnboardingModalUI
        show={show && (!completedBusiness || !completedProfile)}
        close={() => {}}
        submitPersonal={handleUpdateUser}
        submitBusiness={handleCreateCompany}
        stage={stage}
        setStage={setStage}
        countryOptions={countries}
        personalData={
          completedProfile
            ? {
                firstName,
                lastName,
                role,
                number: {
                  code: phone.code,
                  value: `${phone.code}${phone.number}`,
                  number: phone.number,
                },
                how: howOptions.find(
                  (item) => item.value === user.referral
                ) ?? {
                  label: "Others",
                  value: "others",
                },
                other: howOptions.find((item) => item.value === user.referral)
                  ? undefined
                  : user.referral,
                country: { label: country, value: country },
              }
            : undefined
        }
      />
    </>
  );
};

export { OnboardingModal };
