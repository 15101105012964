import styles from "./styles.module.scss";
import {
  Button,
  CustomSelect,
  Input,
  OptionType,
  initOptionType,
  optionTypeSchema,
} from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { objectiveOptions } from "./utils";
import { Routes } from "router";
import { Link } from "react-router-dom";
import { SignupRequestData } from "api";
import { useEffect } from "react";

interface SignupData {
  email: string;
  password: string;
  objective: OptionType;
  agreement: boolean;
}

const initSignup: SignupData = {
  email: "",
  password: "",
  objective: initOptionType,
  agreement: false,
};

// Custom password validation method
yup.addMethod(yup.string, "noEmailInPassword", function (emailRef, message) {
  return this.test("no-email-in-password", message, function (value) {
    const { path, createError, parent } = this;
    const email = parent[emailRef];
    if (email && value) {
      const emailParts = email.split(/[@._]/);
      emailParts.pop()
      for (const part of emailParts) {
        const mailPart = part.toLowerCase().replaceAll(/\d/g, "");
        if (value.toLowerCase().includes(mailPart)) {
          return createError({ path, message });
        }
      }
    }
    return true;
  });
});

// Extend Yup's string schema to include the new method
declare module "yup" {
  interface StringSchema {
    noEmailInPassword(emailRef: string, message: string): this;
  }
}

const schema = yup.object({
  email: yup.string().email("Enter a valid email").required("Required"),
  password: yup
    .string()
    .required("Required")
    .min(8, "Password should be at least 8 characters long")
    .noEmailInPassword(
      "email",
      "Password should not contain parts of the email address"
    )
    .matches(/[A-Z]/, "Password should contain an uppercase character")
    .matches(/[a-z]/, "Password should contain an lowercase character")
    .matches(/[0-9]/, "Password should contain at least one number")
    .matches(
      /[@#&$]/,
      "Password should contain at least special character (e.g. @, #, &, $)"
    ),
  objective: optionTypeSchema(),
  agreement: yup
    .boolean()
    .required("You have to agree to the terms and conditions to continue")
    .oneOf([true], "You have to agree to the terms and conditions to continue"),
});

interface SignupProps {
  submit: (data: SignupRequestData) => void;
  clear: boolean;
}

const SignupUI: React.FC<SignupProps> = ({ submit, clear }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<SignupData>({
    resolver: yupResolver(schema),
    defaultValues: initSignup,
  });

  useEffect(() => {
    reset(initSignup);
  }, [clear]);

  const onSubmit: SubmitHandler<SignupData> = (data) => {
    const submitData: SignupRequestData = {
      email: data.email.toLowerCase().trim(),
      objective: data.objective.value,
      password1: data.password,
      password2: data.password,
    };
    submit(submitData);
  };

  return (
    <>
      <h1 className={styles.ttl}>Sign up</h1>
      <p className={styles.signin}>
        Already have an account? <Link to={Routes.signin}>Sign in</Link>
      </p>

      <form className={styles.form}>
        <Input
          label="Work email"
          placeholder="Enter your work or business email address"
          type="email"
          required
          validatorMessage={errors.email?.message}
          name="email"
          register={register}
          value={watch("email")}
        />
        <Input
          label="Password"
          placeholder="Enter password"
          type="password"
          required
          validatorMessage={errors.password?.message}
          name="password"
          register={register}
          value={watch("password")}
        />
        <CustomSelect
          label="Primary objective for using Precision in the next 30 days:"
          placeholder="Select appropriate"
          options={objectiveOptions}
          onChange={(val) => setValue("objective", val)}
          validatorMessage={errors.objective?.value?.message?.toString() ?? ""}
          name={"objective"}
          value={watch("objective")}
        />
        <div className={styles.checkContainer}>
          <label className={styles.checkWrap}>
            <input
              {...register("agreement", {
                required: true,
              })}
              name="agreement"
              checked={watch("agreement")}
              type="checkbox"
            />
            <span>
              By signing up you agree to the{" "}
              <Link target="_blank" to={Routes.termsOfUse}>
                terms of use
              </Link>{" "}
              and our{" "}
              <Link target="_blank" to={Routes.privacyPolicy}>
                privacy policy
              </Link>
              .
            </span>
          </label>
          {errors.agreement?.message && (
            <p className={styles.errorMsg}>{errors.agreement?.message}</p>
          )}
        </div>
        <Button
          className={styles.btn}
          type="fill"
          onClick={handleSubmit(onSubmit)}
        >
          Sign up
        </Button>
      </form>
    </>
  );
};

export { SignupUI };
