import {
  DropdownMenu,
  DropdownMenuOption,
  Pagination,
  PaginationProps,
  TableBody,
  TableBodyRow,
} from "components/";
import styles from "./styles.module.scss";
import { AssessmentStatuses } from "types";
import {
  CalendarIcon,
  CopyIcon,
  CopyIcon2,
  CopyLinkIcon,
  EyeOpenIcon,
  PauseIcon,
  PeopleIcon,
  PlayIcon,
  TickIcon,
} from "assets";
import { useState } from "react";

export interface ActivatedAssessmentTableItem {
  id: string;
  title: string;
  startDate: string;
  start: {
    date: string;
    time: string;
  };
  end: {
    date: string;
    time: string;
  };
  link: string;
  previewLink: string;
  status: AssessmentStatuses;
}

interface TableBodyProps {
  tableBodyItems: ActivatedAssessmentTableItem[];
  handleStatus: ({ id, status }: { id: string; status: string }) => void;
  tableBodyItemClassName: string;
  tableBodyRowClassName: string;
  pagination?: PaginationProps;
  handleInvite: (id: string, status: string) => void;
  handleDuplicate: (id: string) => void;
  handleExtend: ({ id, startDate }: { id: string; startDate: Date }) => void;
  handleReopen: ({ id, startDate }: { id: string; startDate: Date }) => void;
}

const ActivatedAssessmentTable: React.FC<TableBodyProps> = ({
  tableBodyItemClassName,
  tableBodyItems,
  handleStatus,
  tableBodyRowClassName,
  pagination,
  handleInvite,
  handleDuplicate,
  handleExtend,
  handleReopen,
}) => {
  const mobileWidth = 900;
  const [copied, setCopied] = useState(-1);
  const [mobile, setMobile] = useState(
    window.innerWidth <= mobileWidth ? true : false
  );

  const screenSizeUpdate = () => {
    if (window.innerWidth <= mobileWidth) {
      setMobile(true);
    } else if (window.innerWidth > mobileWidth) {
      setMobile(false);
    }
  };
  const copyToClipBoard = async (link, index) => {
    await navigator.clipboard
      .writeText(link)
      .then((res) => {
        setCopied(index);

        setTimeout(() => {
          setCopied(-1);
        }, 2000);
      })
      .catch(() => {
        setCopied(-1);
      });
  };

  return (
    <>
      <TableBody customClassName={`${styles.tableBody}`}>
        {tableBodyItems.map((item, index) => {
          const actions: DropdownMenuOption[] = [
            {
              text: (
                <>
                  {copied === index ? (
                    <>
                      <TickIcon /> Copied!
                    </>
                  ) : (
                    <>
                      <CopyLinkIcon /> Copy link
                    </>
                  )}
                </>
              ),
              onClick: () => copyToClipBoard(item.link, index),
              key: "copy",
            },
            {
              text: (
                <>
                  {item.status === "active" ? <PauseIcon /> : <PlayIcon />}{" "}
                  {item.status === "active" ? "Pause" : "Resume"} assessment
                </>
              ),
              onClick: () =>
                handleStatus({
                  id: item.id,
                  status: item.status === "active" ? "paused" : "active",
                }),
              key: "play/pause",
            },
            /*    {
              text: (
                <>
                  <CopyIcon2 /> Duplicate assessment
                </>
              ),
              onClick: () => handleDuplicate(item.id),
              key: "duplicate",
            },*/
            {
              text: (
                <>
                  <CalendarIcon /> Extend deadline
                </>
              ),
              onClick: () =>
                handleExtend({
                  id: item.id,
                  startDate: new Date(item.startDate),
                }),
              key: "extend",
            },
            {
              text: (
                <>
                  <CalendarIcon /> Re-open assessment
                </>
              ),
              onClick: () =>
                handleReopen({
                  id: item.id,
                  startDate: new Date(item.startDate),
                }),
              key: "re-open",
            },
            {
              text: (
                <>
                  <a href={item.previewLink} target="_blank">
                    <EyeOpenIcon role="button" />
                    Preview assessment
                  </a>
                </>
              ),
              onClick: () => {},
              key: "preview",
            },
            {
              text: (
                <>
                  <PeopleIcon /> Invite candidates
                </>
              ),
              onClick: () => handleInvite(item.id, item.status),
              key: "invite",
            },
          ];

          const options =
            item.status === "paused"
              ? actions.filter(
                  (item) =>
                    item.key === "play/pause" ||
                    item.key === "preview" ||
                    item.key === "duplicate"
                )
              : item.status === "scheduled"
              ? actions.filter(
                  (item) =>
                    item.key !== "play/pause" &&
                    item.key !== "invite" &&
                    item.key !== "re-open"
                )
              : item.status === "closed"
              ? actions.filter(
                  (item) =>
                    item.key === "preview" ||
                    item.key === "re-open" ||
                    item.key === "duplicate"
                )
              : item.status !== "active"
              ? actions.filter((item) => item.key !== "play/pause")
              : actions.filter((item) => item.key !== "re-open");

          const mobileOptions = options.filter(
            (item) =>
              item.key !== "play/pause" &&
              item.key !== "preview" &&
              item.key !== "copy" &&
              item.key !== "invite"
          );

          return (
            <TableBodyRow
              key={`body ${index}`}
              style={{ zIndex: tableBodyItems.length - index }}
              customClassName={`${styles.tableBodyRow} ${tableBodyRowClassName}`}
            >
              <span className={`${tableBodyItemClassName} ${styles.title}`}>
                <p className={styles.label}>Assessment Title</p>
                {item.title}
              </span>
              <span className={`${tableBodyItemClassName} ${styles.startDate}`}>
                <p className={styles.label}>Start Date (GMT)</p>
                <div className={styles.date}>
                  <span>{item.start.date} </span>
                  <span>{item.start.time}</span>
                </div>
              </span>
              <span className={`${tableBodyItemClassName} ${styles.endDate}`}>
                <p className={styles.label}>End Date (GMT)</p>
                <div className={styles.date}>
                  <span>{item.end.date} </span>
                  <span>{item.end.time}</span>
                </div>
              </span>
              <span className={`${tableBodyItemClassName} ${styles.statusSec}`}>
                <p className={`${styles.status} ${styles[item.status]}`}>
                  {item.status}
                </p>
              </span>
              <span className={`${tableBodyItemClassName} ${styles.menuSec}`}>
                <div className={styles.mobileMenu}>
                  {item.status === "active" || item.status === "paused" ? (
                    <button
                      onClick={() =>
                        handleStatus({
                          id: item.id,
                          status:
                            item.status === "active" ? "paused" : "active",
                        })
                      }
                    >
                      {item.status === "active" ? <PauseIcon /> : <PlayIcon />}{" "}
                      {item.status === "active" ? "Pause" : "Resume"} assessment
                    </button>
                  ) : (
                    ""
                  )}
                  {item.status !== "paused" && item.status !== "closed" ? (
                    copied === index ? (
                      <TickIcon />
                    ) : (
                      <CopyLinkIcon
                        role="button"
                        onClick={() => copyToClipBoard(item.link, index)}
                      />
                    )
                  ) : (
                    ""
                  )}
                  {item.status === "active" ? (
                    <PeopleIcon
                      role="button"
                      title="Invite candidates"
                      onClick={() => handleInvite(item.id, item.status)}
                    />
                  ) : (
                    ""
                  )}
                  {/* 
                  {item.status === "active" ? (
                    <CalendarIcon
                      role="button"
                      title="Extend deadline"
                      onClick={() => handleExtend(item.id)}
                    />
                  ) : (
                    ""
                  )}
                  {item.status === "closed" ? (
                    <CalendarIcon
                      role="button"
                      title="Re-open assessment"
                      onClick={() => handleReopen(item.id)}
                    />
                  ) : (
                    ""
                  )} 
                  <CopyIcon2
                    role="button"
                    title="Duplicate assessment"
                    onClick={() => handleDuplicate(item.id)}
                  />*/}
                  <a href={item.previewLink} target="_blank">
                    <EyeOpenIcon />
                  </a>
                </div>
                <DropdownMenu options={mobile ? mobileOptions : options} />
              </span>
            </TableBodyRow>
          );
        })}
      </TableBody>
      {pagination ? (
        <Pagination {...pagination} className={styles.pagination} />
      ) : (
        ""
      )}
    </>
  );
};

export { ActivatedAssessmentTable };
