import { ComparisonData, Comparisons, EmptyTable, ScoreBar } from "components";
import styles from "./styles.module.scss";
import { useState } from "react";
import { emptyAssessmentsImg } from "assets";
import { MetricHeading, MetricLegend, MetricOverview } from "../components";

export interface WorkStyleData {
  keyQuality: string;
  goodAt: string[];
  poorAt: string[];
}

export interface WorkStyleResultData {
  prioritizing: {
    expectation: number;
    result: number;
    keyQuality: string;
    goodAt: string[];
    poorAt: string[];
  };
  visualizing: {
    expectation: number;
    result: number;
    keyQuality: string;
    goodAt: string[];
    poorAt: string[];
  };
  planning: {
    expectation: number;
    result: number;
    keyQuality: string;
    goodAt: string[];
    poorAt: string[];
  };
  coordinating: {
    expectation: number;
    result: number;
    keyQuality: string;
    goodAt: string[];
    poorAt: string[];
  };
}

export interface WorkStyleProps {
  isFullReport: boolean;
  workStyle: WorkStyleResultData | undefined;
  score: number | undefined;
}

const WorkStyle: React.FC<WorkStyleProps> = ({
  isFullReport,
  workStyle,
  score,
}) => {
  const [active, setActive] = useState(0);
  const [mobile, setMobile] = useState(window.innerWidth <= 900);

  const screenSizeUpdate = () => {
    setMobile(window.innerWidth <= 900);
  };

  window.addEventListener("resize", screenSizeUpdate);

  if (!workStyle || score === undefined)
    return (
      <EmptyTable
        image={emptyAssessmentsImg}
        text="No response found for this metric"
      />
    );

  const data: ComparisonData[] = [
    {
      title: "Prioritizing",
      expectationStr: `${workStyle.prioritizing.expectation}%`,
      scale: workStyle.prioritizing.expectation,
      result: workStyle.prioritizing.result,
      body: <Quality {...workStyle.prioritizing} />,
    },
    {
      title: "Visualizing",
      expectationStr: `${workStyle.visualizing.expectation}%`,
      scale: workStyle.visualizing.expectation,
      result: workStyle.visualizing.result,
      body: <Quality {...workStyle.visualizing} />,
    },
    {
      title: "Planning",
      expectationStr: `${workStyle.planning.expectation}%`,
      scale: workStyle.planning.expectation,
      result: workStyle.planning.result,
      body: <Quality {...workStyle.planning} />,
    },
    {
      title: "Coordinating",
      expectationStr: `${workStyle.coordinating.expectation}%`,
      scale: workStyle.coordinating.expectation,
      result: workStyle.coordinating.result,
      body: <Quality {...workStyle.coordinating} />,
    },
  ];

  return (
    <>
      <section className={styles.wrapper}>
        {isFullReport ? <MetricHeading heading="Work Style" /> : ""}
        <section className={styles.container}>
          <div className={styles.sec1}>
            <MetricOverview
              description={
                "Individuals' approach, preferences, and methodology in carrying out tasks and responsibilities."
              }
              score={score}
            />
            <MetricLegend />
          </div>
          <Comparisons data={data} />
        </section>
      </section>
    </>
  );
};

const Quality: React.FC<WorkStyleData> = ({ keyQuality, goodAt, poorAt }) => {
  return (
    <div className={styles.qualities__item}>
      <div>
        <p className={styles.qualities__item__ttl}>Key Quality </p>
        <p>{keyQuality}</p>
      </div>
      <div>
        <p className={styles.qualities__item__ttl}>Good at </p>
        <ul>
          {goodAt.map((val) => (
            <li>{val}</li>
          ))}
        </ul>
      </div>
      <div>
        <p className={styles.qualities__item__ttl}>Poor at </p>
        <ul>
          {poorAt.map((val) => (
            <li>{val}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { WorkStyle };
