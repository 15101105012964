import { AssessmentOverviewData, CandidateAssessment } from "features";
import { openFullScreen, queryObject } from "helpers";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AssessmentBasicFit } from "./basicFit";
import { AssessmentWorkQualities } from "./workQualities";
import { AssessmentWorkStyle } from "./workStyle";
import { AssessmentPersonality } from "./personality";
import { AssessmentSoftSkills } from "./softSkills";
import { AssessmentDomainKnowledge } from "./domainKnowledge";
import { AssessmentOverview } from "./overview";
import { StartAssessment } from "./startAssessment";
import { getDateTime } from "helpers";
import { useApiRequest, useToast } from "hooks";
import { useParams } from "react-router-dom";
import { fetchCandidateAssessmentInfoService } from "api";
import { Loader } from "components";
import { Routes } from "router";
import { AssessmentWorkMotivators } from "./workMotivators";
import { AssessmentComplete } from "./complete";
import { AssessmentMalpractice } from "./malpractice";
import { Support } from "layout/support";

const initAssessment: AssessmentOverviewData = {
  title: "",
  startDate: "",
  endDate: "",
  time: 0,
  isLive: false,
  total: 0,
  instruction: "",
  metrics: [],
  id: "",
  _id: "",
  employer: { name: "", logo: "" },
};

interface metrics {
  metric: string;
  answered: boolean;
}

export interface AssessmentProgress {
  basic_expectations: boolean;
  work_motivators: boolean;
  work_qualities: boolean;
  work_style: boolean;
  personality: boolean;
  soft_skill: boolean;
  domain_knowledge: boolean;
}

const ViewAssessment = () => {
  const [start, setStart] = useState(false);
  const [metrics, setMetrics] = useState<metrics[]>([]);
  const [startTimer, setStartTimer] = useState(false);

  const { search } = useLocation();
  const navigate = useNavigate();
  const query = queryObject(search);
  const isOverview =
    Object.keys(query).length === 0 || (query.email && query.full_name);
  const hideProgress = !query.metric && !isOverview;

  const { id } = useParams();

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const fetchAssessment = () =>
    id && run(fetchCandidateAssessmentInfoService(id));

  useEffect(() => {
    fetchAssessment();
  }, [id]);

  useEffect(() => {
    if (!!query.metric && query.metric !== "basic-fit") setStartTimer(true);
  }, []);

  const assessment = useMemo<AssessmentOverviewData>(() => {
    if (response?.status === 200) {
      const data = response.data.data;

      const metrics: metrics[] = data.metrics_set.map((item) => ({
        metric: item,
        answered: false,
      }));

      const workCultureIndex = metrics.findIndex(
        ({ metric }) => metric === "work_culture"
      );

      if (workCultureIndex >= 0) {
        const culture = [
          {
            metric: "work_style",
            answered: false,
          },
          {
            metric: "work_motivators",
            answered: false,
          },
          {
            metric: "work_qualities",
            answered: false,
          },
        ];
        metrics.splice(workCultureIndex, 1, ...culture);
      }
      setMetrics(metrics);

      const assessment: AssessmentOverviewData = {
        title: data.title,
        startDate: data.start_date,
        endDate: getDateTime(data.end_date).dateString,
        time: data.duration_in_minutes, //in minutes
        isLive: data.status !== "scheduled",
        total: data.no_of_questions,
        instruction: data.intro_html,
        metrics: data.metrics_set.map((item) => item.replaceAll("_", " ")),
        id: data.assessment_id,
        _id: data.id,
        employer: {
          name: data.company_info.name,
          logo: data.company_info.company_logo ?? "",
        },
      };

      localStorage.setItem("assessment", JSON.stringify(assessment));
      return assessment;
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to fetch assessment information",
        type: "error",
        timeOut: 5000,
      });
    }
    return initAssessment;
  }, [response, error]);

  const handleNext = (metric: string) => {
    openFullScreen();
    const newList = metrics.map((item) =>
      item.metric === metric ? { metric, answered: true } : item
    );
    setMetrics(newList);
    const next = newList.find((metric) => !metric.answered);
    goToNext(next);
  };

  const goToNext = (next: metrics | undefined) => {
    if (next) {
      setStartTimer(true);
      switch (next.metric) {
        case "basic_expectations":
          navigate(Routes.assessmentBasic(id));
          break;
        case "work_motivators":
          navigate(Routes.assessmentWorkMotivators(id));
          break;
        case "work_qualities":
          navigate(Routes.assessmentWorkQualities(id));
          break;
        case "work_style":
          navigate(Routes.assessmentWorkStyle(id));
          break;
        case "personality":
          navigate(Routes.assessmentPersonality(id));
          break;
        case "soft_skill":
          navigate(Routes.assessmentSoftskills(id));
          break;
        case "domain_knowledge":
          navigate(Routes.assessmentDomainKnowledge(id));
          break;
        default:
          navigate(Routes.assessmentOverview(id));
          break;
      }
    } else {
      navigate(Routes.assessmentComplete(id));
    }
  };

  const resumeAssessment = (current: AssessmentProgress) => {
    const newList = metrics.map((item) => ({
      metric: item.metric,
      answered: current[item.metric],
    }));
    setMetrics(newList);
    const next = newList.find((metric) => !metric.answered);
    goToNext(next);
  };

  // const checkFullScreen = () => {
  //   if (
  //     query.complete === "true" ||
  //     query.malpractice === "true" ||
  //     isOverview
  //   ) {
  //     return;
  //   }

  //   const fullScreen =
  //     window.innerWidth === window.screen.width &&
  //     window.innerHeight === window.screen.height;

  //   const isNotAssessmentScreen = query.metric !== "basic-fit";

  //   if (isNotAssessmentScreen && !fullScreen) {
  //     openToast({
  //       show: true,
  //       type: "warning",
  //       heading: "Yikes, you left the full screen mode!",
  //       text: "",
  //       timeOut: 3500,
  //     });
  //     id && navigate(Routes.assessmentMalpractice(id));
  //   }
  // };

  // window.addEventListener("resize", checkFullScreen);

  // // Add an event listener for the resize event
  // window.addEventListener("resize", function () {
  //   // Check if the window width and height match the screen width and height
  //   if (
  //     window.innerWidth === window.screen.width &&
  //     window.innerHeight === window.screen.height
  //   ) {
  //     // Code to handle full-screen mode
  //     console.log("Full-screen mode");
  //   } else {
  //     // Code to handle non-full-screen mode
  //     console.log("Non-full-screen mode");
  //   }
  // });

  // // Add an event listener for the focus event on the input field
  // document.querySelector("input")?.addEventListener("focus", function () {
  //   // Code to handle keyboard events separately
  //   console.log("Keyboard is active");
  //   window.removeEventListener("resize", checkFullScreen);
  // });

  const [autoSubmit, setAutoSubmit] = useState(false);

  const handleTimeout = () => {
    if (query.metric === "domain-knowledge") {
      setAutoSubmit(true);
    } else {
      navigate(Routes.assessmentComplete(id));
    }
  };

  return (
    <>
      <Loader loading={requestStatus.isPending} />
      <StartAssessment
        show={start}
        close={() => setStart(false)}
        id={id ?? ""}
        resume={resumeAssessment}
      />
      {!requestStatus.isPending && (
        <CandidateAssessment
          startDate={assessment.startDate}
          length={assessment.total}
          isLive={assessment.isLive}
          isOverview={isOverview || hideProgress}
          duration={assessment.time}
          employer={assessment.employer}
          handleTimeout={handleTimeout}
          startTimer={startTimer}
        >
          {response?.data?.data?.status === "paused" ? (
            <AssessmentComplete
              title="Paused Assessment"
              text="This assessment is temporarily unavailable, please check back"
            />
          ) : response?.data?.data?.status === "closed" ? (
            <AssessmentComplete
              title="Closed Assessment"
              text="This assessment is unavailable, please check back"
            />
          ) : isOverview ? (
            <AssessmentOverview
              assessment={assessment}
              handleStart={() => {
                setStart(true);
              }}
              candidate={query.full_name}
            />
          ) : query.metric === "basic-fit" ? (
            <AssessmentBasicFit
              assessmentID={id ?? ""}
              hasBasicExpectation={
                assessment.metrics.find((item) => item === "basic expectations")
                  ? true
                  : false
              }
              callback={handleNext}
              resume={resumeAssessment}
            />
          ) : query.metric === "work-qualities" ? (
            <AssessmentWorkQualities callback={handleNext} />
          ) : query.metric === "work-motivators" ? (
            <AssessmentWorkMotivators callback={handleNext} />
          ) : query.metric === "work-style" ? (
            <AssessmentWorkStyle callback={handleNext} />
          ) : query.metric === "personality" ? (
            <AssessmentPersonality callback={handleNext} />
          ) : query.metric === "soft-skills" ? (
            <AssessmentSoftSkills callback={handleNext} />
          ) : query.metric === "domain-knowledge" ? (
            <AssessmentDomainKnowledge
              callback={handleNext}
              autoSubmit={autoSubmit}
            />
          ) : query.complete === "true" ? (
            <AssessmentComplete />
          ) : query.malpractice === "true" ? (
            <AssessmentMalpractice />
          ) : (
            ""
          )}
        </CandidateAssessment>
      )}
      <Support
        text="Need help?"
        message={`Hello, I am a candidate from ${assessment.employer.name} and I am taking an assessment for the ${assessment.title} role. I need help with a problem I'm having.`}
      />
    </>
  );
};

export { ViewAssessment };
