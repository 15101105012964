import styles from "./styles.module.scss";
import { Button, HalfDoughnutChart, ToolTip } from "components";
import {
  CardIcon,
  MastercardIcon,
  TickIconFill,
  VerveIcon,
  VisaIcon,
} from "assets";
import { AutoScoring, AutoScoringProps } from "./autoScoring";
import { useAppSelector } from "state/hooks";
import { AllPlansData } from "state";

export interface PlanProps {
  handleExtendAccessUnavailable: () => void;
  handleExtendAccess: () => void;
  handleTopup: () => void;
  handleCustomPlan: () => void;
  handleBuyPlan: (plan: AllPlansData) => void;
  planData: {
    plan: string;
    billingCycle: string;
    rolesAllowed: number;
    rolesUsed: number;
    candidatesAllowed: number;
    candidatesUsed: number;
    status: string;
    daysLeft: number;
    amount: string;
  };
  handleCancelSub: () => void;
  card: { type: string; digits: string } | undefined;
  handleSaveCard: () => void;
  autoScoring: AutoScoringProps;
}
export interface PlanUIProps extends PlanProps {
  currency: { value: string; onChange: (currency: string) => void };
}

const Plan: React.FC<PlanUIProps> = ({
  handleExtendAccessUnavailable,
  handleExtendAccess,
  handleTopup,
  handleCustomPlan,
  handleBuyPlan,
  planData,
  handleCancelSub,
  card,
  handleSaveCard,
  autoScoring,
  currency,
}) => {
  const isPayg = planData.plan === "pay_as_you_go";
  const isFreeTrial = planData.plan === "free_trial";
  const plans = useAppSelector((state) => state.plans);

  const extendAccess = () => {
    isPayg || isFreeTrial
      ? handleExtendAccessUnavailable()
      : handleExtendAccess();
  };

  const buyPlan = (plan) => {
    const myPlan = plans.find((item) => item.key === plan);
    myPlan && handleBuyPlan(myPlan);
  };

  const candidateMonitoring =
    (planData.candidatesUsed / planData.candidatesAllowed) * 100;
  const roleMonitoring = (
    (planData.rolesUsed / planData.rolesAllowed) *
    100
  ).toPrecision(2);

  const currentPlan = plans.find((item) => item.key === planData.plan);
  const isSubscriptionPlan =
    currentPlan?.key === "regular" ||
    currentPlan?.key === "pro" ||
    planData.plan === "custom" ||
    planData.billingCycle === "monthly" ||
    planData.billingCycle === "annual";

  const noActivePlan = planData.status !== "active";

  const paygPlan = plans.find((item) => item.key === "pay_as_you_go");
  const regularPlan = plans.find((item) => item.key === "regular");
  const proPlan = plans.find((item) => item.key === "pro");

  return (
    <>
      <section className={styles.sec1}>
        <div className={styles.plan}>
          <div className={styles.plan__tags}>
            <div className={styles.plan__tag}>Current plan</div>
            {isSubscriptionPlan && !noActivePlan ? (
              <button
                onClick={handleCancelSub}
                className={styles.plan__cancelBtn}
              >
                Cancel subscription
              </button>
            ) : (
              ""
            )}
          </div>
          <div className={styles.plan__hd}>
            <p className={styles.plan__hd__ttl}>
              {planData.plan === "free_trial"
                ? "Free trial"
                : currentPlan?.title ?? planData.plan}
              {!isPayg && <span>{planData.billingCycle}</span>}
            </p>
            <p
              className={`${styles.plan__hd__status} ${
                styles[`plan__hd__status--${planData.status}`]
              }`}
            >
              {planData.status}
            </p>
          </div>
          <div className={styles.plan__info}>
            <div className={styles.plan__info__scale}>
              <div>
                <div style={{ width: `${roleMonitoring}%` }}></div>
              </div>
              <p>
                {planData.rolesUsed} of {planData.rolesAllowed} roles
              </p>
            </div>
            <div className={styles.plan__info__price}>
              <p>{planData.amount}</p>
              <span>
                per{" "}
                {isPayg
                  ? "role"
                  : `${planData.billingCycle === "monthly" ? "month" : "year"}`}
              </span>
            </div>
            <p className={styles.plan__info__txt}>
              Top-up price for additional candidates asides the allotted $0.6
            </p>
          </div>
          {card ? (
            <div className={`${styles.plan__footer} ${styles.plan__card}`}>
              {card.type.includes("mastercard") ? (
                <MastercardIcon />
              ) : card.type.includes("verve") ? (
                <VerveIcon />
              ) : card.type.includes("visa") ? (
                <VisaIcon />
              ) : (
                <CardIcon />
              )}
              <p>
                This account is billed to{" "}
                <b>
                  {card.type} card ending in {card?.digits}
                </b>
              </p>
              <button onClick={handleSaveCard}>Change credit card</button>
            </div>
          ) : (
            ""
          )}
          {noActivePlan ? (
            ""
          ) : (
            <div style={{ marginTop: "0" }} className={styles.plan__footer}>
              <p>
                Plan expires in <b>{planData.daysLeft} days</b>
              </p>
              <button disabled={noActivePlan} onClick={extendAccess}>
                Extend access
              </button>
            </div>
          )}
        </div>
        <div className={styles.monitoring}>
          <div className={styles.monitoring__hd}>
            <p className={styles.monitoring__hd__ttl}>Candidate monitoring</p>
            <p className={styles.monitoring__hd__txt}>
              You have used <b>{planData.candidatesUsed}</b> of{" "}
              {planData.candidatesAllowed} allocated candidates on this plan
            </p>
          </div>
          <div>
            <HalfDoughnutChart score={candidateMonitoring} />
          </div>
          <div className={styles.monitoring__info}>
            <p className={styles.monitoring__info__txt1}>
              {candidateMonitoring === 100
                ? "You have reached your limit."
                : candidateMonitoring > 70
                ? "You have almost reached your limit"
                : "You have adequate slots left"}
            </p>
            <p className={styles.monitoring__info__txt2}>
              You have used {candidateMonitoring >= 100 ? 100 : candidateMonitoring.toPrecision(2)}% of your
              allocated candidate spot on this plan. Top-up or upgrade your plan
            </p>
          </div>
          <div className={styles.monitoring__footer}>
            <button
              disabled={isFreeTrial || noActivePlan}
              onClick={handleTopup}
            >
              Top-up candidate
            </button>
          </div>
        </div>
      </section>
      <AutoScoring {...autoScoring} />
      <section className={styles.sec2}>
        <div className={styles.sec2__hd}>
          <p>Plans</p> <button onClick={handleCustomPlan}>Custom plan</button>
        </div>
        <div className={styles.pricing}>
          <div className={styles.pricing__plan}>
            <p
              className={`${styles.pricing__plan__tag} ${styles["pricing__plan__tag--pay_as_you_go"]}`}
            >
              Pay As You Go
            </p>
            <p className={styles.pricing__plan__txt}>
              Assess candidates on-demand for specific roles with no additional
              charges.
            </p>
            <div className={styles.pricing__plan__fee}>
              <p>
                {getCost({
                  dollarCost: paygPlan?.fee,
                  nairaCost: paygPlan?.nairaFee,
                  currency: currency.value,
                })}
              </p>{" "}
              <span>per role</span>
            </div>
            <ul className={styles.pricing__plan__benefits}>
              <li>
                <TickIconFill /> Assess up to {paygPlan?.candidates} candidates
                per role
              </li>
              <li>
                <TickIconFill /> 30 days access
              </li>
              <li>
                <TickIconFill /> {paygPlan?.autoScoringCredit} free auto-scoring
                credits{" "}
                <ToolTip
                  title="Auto-scoring"
                  text={`Eliminate manual report review, activate this feature to
                  automate the scoring of open-ended responses for default
                  questions.`}
                  position="left"
                  iconSize="small"
                />
              </li>
            </ul>
            <Button type="fill" onClick={() => buyPlan("pay_as_you_go")}>
              Buy plan
            </Button>
          </div>
          <div className={styles.pricing__plan}>
            <p
              className={`${styles.pricing__plan__tag} ${styles["pricing__plan__tag--regular"]}`}
            >
              Regular
            </p>
            <p className={styles.pricing__plan__txt}>
              Streamline your candidate assessments with our Monthly
              Subscription plan. Assess candidates for more roles.
            </p>
            <div className={styles.pricing__plan__fee}>
              <p>
                {getCost({
                  dollarCost: regularPlan?.fee,
                  nairaCost: regularPlan?.nairaFee,
                  currency: currency.value,
                })}
              </p>{" "}
              <span>per month</span>
            </div>
            <ul className={styles.pricing__plan__benefits}>
              <li>
                <TickIconFill /> Assess up to {regularPlan?.candidates}{" "}
                candidates
              </li>
              <li>
                <TickIconFill /> Access to {regularPlan?.roles} roles
              </li>
              <li>
                <TickIconFill /> {regularPlan?.autoScoringCredit} free
                auto-scoring credits{" "}
                <ToolTip
                  title="Auto-scoring"
                  text={`Eliminate manual report review, activate this feature to
                  automate the scoring of open-ended responses for default
                  questions.`}
                  position="left"
                  iconSize="small"
                />
              </li>
            </ul>
            <Button type="fill" onClick={() => buyPlan("regular")}>
              Buy plan
            </Button>
          </div>
          <div className={styles.pricing__plan}>
            <p
              className={`${styles.pricing__plan__tag} ${styles["pricing__plan__tag--pro"]}`}
            >
              Pro
            </p>
            <p className={styles.pricing__plan__txt}>
              Streamline your candidate assessments with our Monthly
              Subscription plan. Assess candidates for more roles.
            </p>
            <div className={styles.pricing__plan__fee}>
              <p>
                {getCost({
                  dollarCost: proPlan?.fee,
                  nairaCost: proPlan?.nairaFee,
                  currency: currency.value,
                })}
              </p>{" "}
              <span>per month</span>
            </div>
            <ul className={styles.pricing__plan__benefits}>
              <li>
                <TickIconFill /> Assess up to {proPlan?.candidates} candidates
              </li>
              <li>
                <TickIconFill /> Access to {proPlan?.roles} roles
              </li>
              <li>
                <TickIconFill /> {proPlan?.autoScoringCredit} free auto-scoring
                credits{" "}
                <ToolTip
                  title="Auto-scoring"
                  text={`Eliminate manual report review, activate this feature to
                  automate the scoring of open-ended responses for default
                  questions.`}
                  position="left"
                  iconSize="small"
                />
              </li>
            </ul>
            <Button type="fill" onClick={() => buyPlan("pro")}>
              Buy plan
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

const getCost = ({ nairaCost, dollarCost, currency }) => {
  return `${currency === "USD" ? "$" : "₦"}${
    currency === "USD"
      ? dollarCost?.toLocaleString() ?? dollarCost
      : nairaCost?.toLocaleString() ?? nairaCost
  }`;
};

export { Plan };
