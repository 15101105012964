import { customizeEmailReqData, customizeEmailService } from "api";
import { Loader } from "components";
import { CustomizedEmailData, CustomizedEmailUI } from "features";
import { useApiRequest, useToast } from "hooks";
import { useMemo, useState } from "react";

interface CustomizedEmailProps {
  back: () => void;
  callback: (data: CustomizedEmailData) => void;
  skip: () => void;
  title: string;
  logo: string;
  company: string;
  initData: CustomizedEmailData | undefined;
}

const CustomizedEmail = ({
  back,
  title,
  skip,
  logo,
  company,
  callback,
  initData,
}: CustomizedEmailProps) => {
  const [customEmail, setCusomEmailData] = useState<
    CustomizedEmailData | undefined
  >(initData);

  const id: number = parseInt(localStorage.getItem("assessmentId") ?? "");

  // Hooks
  const { openToast } = useToast();

  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const handleCustomization = (data: CustomizedEmailData) => {
    setCusomEmailData(data);
    const payload: customizeEmailReqData = {
      assessment: id,
      email_subject: data.subject,
      email_body: data.section1,
      email_body2: data.section2,
    };
    run(customizeEmailService(payload));
  };

  useMemo(() => {
    if (response?.status === 201) {
      customEmail && callback(customEmail);
    } else if (error) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.error?.message ??
          "Failed to submit your assessment email, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  const showLoader = requestStatus.isPending;

  return (
    <>
      <Loader loading={showLoader} />
      <CustomizedEmailUI
        back={back}
        title={title}
        skip={skip}
        submit={handleCustomization}
        logo={logo}
        company={company}
        initData={customEmail}
      />
    </>
  );
};

export { CustomizedEmail };
