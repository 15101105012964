import styles from "./styles.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Input,
  CustomPhoneInput,
  Button,
  CustomSelect,
  OptionType,
  initOptionType,
  optionTypeSchema,
} from "components";
import { frequencyOptions, employeeOptions } from "utils/options";
import { UpdateCompanyRequestData, UpdateUserRequestData } from "api";
import { useEffect } from "react";
import { EditIcon } from "assets";

interface SettingsUIProps {
  submitPersonal: (data: UpdateUserRequestData) => void;
  submitBusiness: (data: UpdateCompanyRequestData) => void;
  personalData: PersonalInfoData;
  companyData: CompanyInfoData;
  avatar: string;
  logo: string;
  handleImageUpload: (type: "avatar" | "company logo") => void;
  handleChangePassword: () => void;
  countryOptions: OptionType[];
}

const SettingsUI: React.FC<SettingsUIProps> = ({
  personalData,
  companyData,
  submitBusiness,
  submitPersonal,
  avatar,
  logo,
  handleImageUpload,
  handleChangePassword,
  countryOptions,
}) => {
  return (
    <>
      <section className={styles.container}>
        <section>
          <div className={styles.profileHeader}>
            <div className={styles.avatarWrap}>
              <EditIcon
                role="button"
                onClick={() => handleImageUpload("avatar")}
              />
              {avatar && avatar !== "" ? <img src={avatar} /> : "Avatar"}
            </div>

            <Button onClick={handleChangePassword} type="outline">
              Change password
            </Button>
          </div>
          <PersonalInfoForm
            countryOptions={countryOptions}
            init={personalData}
            submit={submitPersonal}
          />
        </section>
        <section>
          <div className={styles.profileHeader}>
            <div className={styles.avatarWrap}>
              <EditIcon
                role="button"
                onClick={() => handleImageUpload("company logo")}
              />
              {logo && logo !== "" ? <img src={logo} /> : "Logo"}
            </div>
          </div>
          <CompanyInfoForm init={companyData} submit={submitBusiness} />
        </section>
      </section>
    </>
  );
};

interface PersonalInfoData {
  firstName: string;
  lastName: string;
  role: string;
  number: { code: string; number: string; value: string };
  country: OptionType;
}

const personalSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  role: yup.string().required("Required"),
  number: yup.object({
    code: yup.string().required("Required"),
    number: yup.string().required("Required"),
    value: yup.string().required("Required"),
  }),
  country: optionTypeSchema(),
});

interface PersonalInfoProps {
  submit: (data: UpdateUserRequestData) => void;
  init: PersonalInfoData;
  countryOptions: OptionType[];
}
const PersonalInfoForm: React.FC<PersonalInfoProps> = ({
  submit,
  init,
  countryOptions,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    setValue,
    reset,
  } = useForm<PersonalInfoData>({
    resolver: yupResolver(personalSchema),
    defaultValues: init,
  });

  useEffect(() => {
    reset(init);
  }, [init]);

  const handleCancel = () => reset(init);

  const numberChange =
    watch("number").value.replaceAll(" ", "") !== init.number.value;
  const countryChange = watch("country").value !== init.country.value;

  const onSubmit: SubmitHandler<PersonalInfoData> = (data) => {
    let submitData: UpdateUserRequestData = {};

    if (dirtyFields.firstName) submitData.first_name = data.firstName;
    if (dirtyFields.lastName) submitData.last_name = data.lastName;
    if (numberChange) {
      submitData.country_code = data.number.code;
      submitData.phone_number = data.number.number;
    }
    if (countryChange) {
      submitData.country = data.country.value;
    }

    submit(submitData);
  };

  const disableEdit = !(
    Object.keys(dirtyFields).length > 0 ||
    numberChange ||
    countryChange
  );

  return (
    <form>
      <Input
        label="First name"
        placeholder="Your first name"
        type="text"
        required
        validatorMessage={errors.firstName?.message}
        name="firstName"
        register={register}
        value={watch("firstName")}
        showRequired
      />
      <Input
        label="Last name"
        placeholder="Your last name"
        type="text"
        required
        validatorMessage={errors.lastName?.message}
        name="lastName"
        register={register}
        value={watch("lastName")}
        showRequired
      />
      <Input
        label="Your job role"
        placeholder="Your job role at the company"
        type="text"
        required
        validatorMessage={errors.role?.message}
        name="role"
        register={register}
        value={watch("role")}
        showRequired
      />
      <CustomPhoneInput
        label="Phone number"
        placeholder="Your phone number"
        validatorMessage={errors.number?.value?.message?.toString() ?? ""}
        onChange={({ code, number, value }) =>
          setValue("number", { code, number, value })
        }
        showRequired
        value={watch("number.value")}
      />
      <CustomSelect
        menuPlacement="top"
        label="Country"
        placeholder="Select country"
        options={countryOptions}
        onChange={(val) => setValue("country", val)}
        validatorMessage={errors.country?.value?.message?.toString() ?? ""}
        name={"country"}
        value={watch("country")}
        showRequired
      />

      <div className={styles.btnSec}>
        <Button
          disabled={disableEdit}
          className={styles.btn}
          type="outline"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={disableEdit}
          className={styles.btn}
          type="fill"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

interface CompanyInfoData {
  name: string;
  industry: string;
  frequency: OptionType;
  employees: OptionType;
}

const companySchema = yup.object({
  name: yup.string().required("Required"),
  industry: yup.string().required("Required"),
  frequency: optionTypeSchema(),
  employees: optionTypeSchema(),
});

interface CompanyInfoProps {
  submit: (data: UpdateCompanyRequestData) => void;
  init: CompanyInfoData;
}

const CompanyInfoForm: React.FC<CompanyInfoProps> = ({ submit, init }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    watch,
    setValue,
    reset,
  } = useForm<CompanyInfoData>({
    resolver: yupResolver(companySchema),
    defaultValues: init,
  });

  useEffect(() => {
    reset(init);
  }, [init]);

  const handleCancel = () => reset(init);

  const employeesChange = watch("employees.value") !== init.employees.value;
  const frequencyChange = watch("frequency.value") !== init.frequency.value;

  const onSubmit: SubmitHandler<CompanyInfoData> = (data) => {
    const submitData: UpdateCompanyRequestData = {};

    if (dirtyFields.name) submitData.name = data.name;
    if (dirtyFields.industry) submitData.industry = data.industry;
    if (frequencyChange) submitData.hiring_frequency = data.frequency.value;
    if (employeesChange) submitData.no_of_employees = data.employees.value;

    submit(submitData);
  };

  const disableEdit = !(isDirty || employeesChange || frequencyChange);

  return (
    <form>
      <Input
        label="Company’s name"
        placeholder="Your name"
        type="text"
        required
        validatorMessage={errors.name?.message}
        name="name"
        register={register}
        value={watch("name")}
        showRequired
      />
      <Input
        label="Industry"
        placeholder="Mention the industry your organization operates in"
        type="text"
        required
        validatorMessage={errors.industry?.message}
        name="industry"
        register={register}
        value={watch("industry")}
        showRequired
      />
      <CustomSelect
        label="Annual hiring frequency"
        placeholder="Select appropriate"
        options={frequencyOptions}
        onChange={(val) => setValue("frequency", val)}
        validatorMessage={errors.frequency?.value?.message?.toString() ?? ""}
        name={"frequency"}
        value={watch("frequency")}
        showRequired
      />
      <div className={styles.employees}>
        <label>Number of Employees</label>
        <div>
          {employeeOptions.map((item) => (
            <button
              key={item.label}
              className={
                watch("employees").value === item.value ? styles.selected : ""
              }
              onClick={(e) => {
                e.preventDefault();
                setValue("employees", item);
              }}
            >
              <span>{item.label}</span>
              Employees
            </button>
          ))}
          {errors.employees?.value?.message && (
            <p className={styles.errorMsg}>
              {errors.employees?.value?.message?.toString()}
            </p>
          )}
        </div>
      </div>
      <div className={styles.btnSec}>
        <Button
          disabled={disableEdit}
          className={styles.btn}
          type="outline"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={disableEdit}
          className={styles.btn}
          type="fill"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export { SettingsUI };
