/*
=================================
ACCOUNT SERVICES
=================================
*/

import {
  changePasswordURL,
  clearAllNotifsURL,
  clearNotifURL,
  createCompanyURL,
  fetchGraphDataURL,
  fetchNotifsURL,
  fetchStatsURL,
  fetchUserURL,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
  readAllNotifsURL,
  readNotifURL,
  updateCompanyURL,
  updateUserURL,
} from "api";

export interface UpdateUserRequestData {
  username?: string;
  email?: string;
  phone_number?: string;
  first_name?: string;
  last_name?: string;
  country_code?: string;
  date_of_birth?: string;
  display_photo?: string;
  address?: string;
  referral?: string;
  role?: string;
  country?: string;
}

/**
 * Update user service
 * @returns axios promise
 */

export const updateUserService = (data: UpdateUserRequestData) => {
  const requestData = {
    url: updateUserURL(),
    data,
  };
  return patchRequest(requestData);
};

export interface CreateCompanyRequestData {
  name: string;
  industry: string;
  hiring_frequency: string;
  no_of_employees: string;
  company_logo?: string;
}

/**
 * Create company service
 * @returns axios promise
 */

export const createCompanyService = (data: CreateCompanyRequestData) => {
  const requestData = {
    url: createCompanyURL(),
    data,
  };
  return postRequest(requestData);
};

/**
 * Fetch user information service
 * @returns axios promise
 */

export const fetchUserService = () => {
  const requestData = {
    url: fetchUserURL(),
  };
  return getRequest(requestData);
};

export interface UpdateCompanyRequestData {
  name?: string;
  industry?: string;
  hiring_frequency?: string;
  no_of_employees?: string;
  company_logo?: string;
}

/**
 * Update company service
 * @returns axios promise
 */

export const updateCompanyService = (data: UpdateCompanyRequestData) => {
  const requestData = {
    url: updateCompanyURL(),
    data,
  };
  return putRequest(requestData);
};

export interface changePasswordRequestData {
  old_password: string;
  new_password: string;
}

/**
 * Change password service
 * @returns axios promise
 */

export const changePasswordService = (data: changePasswordRequestData) => {
  const requestData = {
    url: changePasswordURL(),
    data,
  };
  return postRequest(requestData);
};

/**
 * Fetch stats service
 * @returns axios promise
 */

export const fetchStatsService = () => {
  const requestData = {
    url: fetchStatsURL(),
  };
  return getRequest(requestData);
};

/**
 * Fetch graph data service
 * @returns axios promise
 */

export const fetchGraphDataService = (period) => {
  const requestData = {
    url: fetchGraphDataURL(period),
  };
  return getRequest(requestData);
};

/**
 * Fetch notifications service
 * @returns axios promise
 */

export const fetchNotifsService = () => {
  const requestData = {
    url: fetchNotifsURL(),
  };
  return getRequest(requestData);
};

/**
 * Clear all notifications service
 * @returns axios promise
 */

export const clearAllNotifsService = () => {
  const requestData = {
    url: clearAllNotifsURL(),
  };
  return getRequest(requestData);
};

/**
 * Read all notifications service
 * @returns axios promise
 */

export const readAllNotifsService = () => {
  const requestData = {
    url: readAllNotifsURL(),
  };
  return getRequest(requestData);
};

/**
 * Clear single notification service
 * @returns axios promise
 */

export const clearNotifService = (id) => {
  const requestData = {
    url: clearNotifURL(id),
  };
  return getRequest(requestData);
};

/**
 * Read single notification service
 * @returns axios promise
 */

export const readNotifService = (id) => {
  const requestData = {
    url: readNotifURL(id),
  };
  return getRequest(requestData);
};
