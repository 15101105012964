import { ToolTip } from "components";
import styles from "./styles.module.scss";

export interface AutoScoringProps {
  handleTopupCredits: () => void;
  creditLeft: number;
  creditUsed: number;
}

const AutoScoring = ({
  handleTopupCredits,
  creditUsed,
  creditLeft,
}: AutoScoringProps) => {
  return (
    <>
      <section className={styles.container}>
        <div className={styles.heading}>
          <p className={styles.heading__ttl}>Auto Scoring </p>
          <ToolTip
            title="Auto-scoring"
            text={`Eliminate manual report review, activate this feature to
                  automate the scoring of open-ended responses for default
                  questions.`}
            position="right"
          />
        </div>
        <div className={styles.body}>
          <div className={styles.credits}>
            <div>
              <p>Credit Used</p>
              <p>{creditUsed}</p>
            </div>
            <div>
              <p>Credit Balance</p>
              <p>{creditLeft}</p>
            </div>
          </div>
          <div className={styles.info}>
            <p className={styles.info__txt1}>
              {creditLeft === 100
                ? "You have reached your limit."
                : creditLeft > 70
                ? "You have almost reached your limit"
                : "You have adequate slots left"}
            </p>
            <p className={styles.info__txt2}>
              You have used {creditLeft.toPrecision(2)}% of your alloted
              auto-scoring credits on this plan. Top-up your credit
            </p>
            <button onClick={handleTopupCredits}>Top-up credit</button>
          </div>
        </div>
      </section>
    </>
  );
};

export { AutoScoring };
