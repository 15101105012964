import { Button, CustomSelect, OptionType } from "components";
import { Header, HeaderProps } from "./header";
import styles from "./styles.module.scss";
import { useState } from "react";
import { BasicExpectation, BasicExpectationProps } from "./basicExpectations";
import { SoftSkills, SoftSkillsProps } from "./softSkills";
import { WorkStyle, WorkStyleProps } from "./workStyle";
import { WorkQualities, WorkQualitiesProps } from "./workQualities";
import { WorkMotivators, WorkMotivatorsProps } from "./workMotivators";
import { Personality, PersonalityProps } from "./personality";
import { DomainKnowledge, DomainKnowledgeReportProps } from "./domainKnowledge";
import { LogoWithText } from "assets";
import { bucketOptions } from "utils/options";
import { moveReportRequestData } from "api";
import { Link } from "react-router-dom";
import { Routes } from "router";

interface TabOptions extends OptionType {
  key: string;
}

const tabs: TabOptions[] = [
  {
    label: "Basic expectations",
    value: "expectations",
    key: "basic_expectations",
  },
  {
    label: "Soft skills",
    value: "soft-skills",
    key: "soft_skill",
  },
  {
    label: "Work style",
    value: "work-style",
    key: "work_culture",
  },
  {
    label: "Workplace qualities",
    value: "work-qualities",
    key: "work_culture",
  },
  {
    label: "Work motivators",
    value: "work-motivators",
    key: "work_culture",
  },
  {
    label: "Personality",
    value: "personality",
    key: "personality",
  },
  {
    label: "Domain knowledge",
    value: "domain",
    key: "domain_knowledge",
  },
];

interface ReportProps {
  metrics: string[];
  role: string;
  candidate: HeaderProps;
  basicExpectation: BasicExpectationProps;
  softSkill: SoftSkillsProps;
  workStyle: WorkStyleProps;
  workQualities: WorkQualitiesProps;
  workMotivators: WorkMotivatorsProps;
  personality: PersonalityProps;
  domainKnowledge: DomainKnowledgeReportProps;
  handleMove: (data: moveReportRequestData) => void;
  id: string;
  roleId: string;
  isPublic: boolean;
}

const ReportUI: React.FC<ReportProps> = ({
  metrics,
  candidate,
  role,
  roleId,
  basicExpectation,
  softSkill,
  workStyle,
  workQualities,
  workMotivators,
  personality,
  handleMove,
  id,
  domainKnowledge,
  isPublic,
}) => {
  const resultTabs: TabOptions[] = tabs.filter((obj) =>
    metrics.includes(obj.key)
  );

  const [active, setActiveTab] = useState(resultTabs[0].value);
  const { isFullReport } = candidate;

  const options = bucketOptions.filter((item) => item.value !== "reject");

  // Find the index of the object with a matching 'value'
  const index = options.findIndex(
    (option) => option.value === candidate.candidate.bucket
  );

  // Create a new array with elements after the matching index
  const newBucketOptions =
    index !== -1
      ? options.slice(index + 1)
      : candidate.candidate.bucket === "assessed"
      ? options
      : [];

  const metricIsSet = (key): boolean =>
    isFullReport && resultTabs.find((item) => item.value === key)
      ? true
      : false;

  return (
    <>
      {!isFullReport ? (
        <p className={styles.txt}>
          <Link to={Routes.employerReports}>Assessment</Link> /{" "}
          <Link to={Routes.employerReportRoleID(roleId)} state={{ role }}>
            {role.replaceAll(/[^a-zA-Z\d\s:]/g, "_")}
          </Link>{" "}
          / Individual reports
        </p>
      ) : (
        ""
      )}
      <section style={{ background: isFullReport ? "white" : "initial" }}>
        <section className={isFullReport ? styles.full : ""}>
          {isFullReport && (
            <div className={styles.logoSec}>
              <div className={styles.logo}>
                <LogoWithText />
              </div>
            </div>
          )}
          <Header {...candidate} />
          <section className={styles.container}>
            {!isFullReport ? (
              <div
                style={{
                  gridTemplateColumns: `repeat(${resultTabs.length}, 1fr)`,
                }}
                className={styles.navTabs}
              >
                {resultTabs.map((tab, index) => (
                  <span
                    key={index}
                    className={tab.value === active ? styles.active : ""}
                    role="button"
                    onClick={() => setActiveTab(tab.value)}
                  >
                    {tab.label}
                  </span>
                ))}
              </div>
            ) : (
              ""
            )}
            {(active === "expectations" || metricIsSet("expectations")) && (
              <BasicExpectation {...basicExpectation} />
            )}
            {(active === "soft-skills" || metricIsSet("soft-skills")) && (
              <SoftSkills {...softSkill} />
            )}
            {(active === "work-style" || metricIsSet("work-style")) && (
              <WorkStyle {...workStyle} />
            )}
            {(active === "work-qualities" || metricIsSet("work-qualities")) && (
              <WorkQualities {...workQualities} />
            )}
            {(active === "work-motivators" ||
              metricIsSet("work-motivators")) && (
              <WorkMotivators {...workMotivators} />
            )}
            {(active === "personality" || metricIsSet("personality")) && (
              <Personality {...personality} />
            )}
            {(active === "domain" || metricIsSet("domain")) && (
              <DomainKnowledge {...domainKnowledge} />
            )}
          </section>
          {isPublic || candidate.candidate.bucket === "malpractice" ? (
            ""
          ) : (
            <section className={styles.actions}>
              <Button
                className={styles.actions__btn}
                type={"fill"}
                onClick={() =>
                  handleMove({
                    new_bucket: "reject",
                    selected_responses: [parseInt(id)],
                  })
                }
              >
                Reject
              </Button>
              <CustomSelect
                onChange={(val) =>
                  handleMove({
                    new_bucket: val.value,
                    selected_responses: [parseInt(id)],
                  })
                }
                validatorMessage={""}
                name={""}
                placeholder={"Move to next stage"}
                options={newBucketOptions}
                value={
                  bucketOptions.find(
                    (item) => item.value === candidate.candidate.bucket
                  ) ?? {
                    label: "",
                    value: "",
                  }
                }
                parentClassName={styles.actions__select}
              />
            </section>
          )}
        </section>
      </section>
    </>
  );
};

export { ReportUI };
