import {
  updateUserService,
  updateCompanyService,
  UpdateUserRequestData,
  UpdateCompanyRequestData,
} from "api";
import { ImageUpload, Loader } from "components";
import { SettingsUI } from "features";
import {
  useToast,
  useFetchUser,
  useApiRequest,
  useFetchCountries,
} from "hooks";
import { Navbar } from "layout";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "state/hooks";
import { ChangePassword } from "./changePassword";

const Settings = () => {
  // States
  const [image, setImage] = useState({ show: false, type: "avatar" });
  const [password, setPassword] = useState(false);

  // Hooks
  const user = useAppSelector((state) => state.user);
  const {
    firstName,
    lastName,
    role,
    phone,
    company,
    industry,
    hiringFrequency,
    noOfEmployees,
    avatar,
    companyLogo,
    country,
  } = user;
  const { openToast } = useToast();
  const { getUser } = useFetchUser();
  const {
    fetchCountries,
    countries,
    loading: loadingCountries,
  } = useFetchCountries();

  // API Request Hooks
  const {
    run: runUpdateUser,
    data: updateUserResponse,
    requestStatus: updateUserStatus,
    error: updateUserError,
  } = useApiRequest({});
  const {
    run: runUpdateCompany,
    data: updateCompanyResponse,
    requestStatus: updateCompanyStatus,
    error: updateCompanyError,
  } = useApiRequest({});

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleUpdateUser = (data: UpdateUserRequestData) => {
    runUpdateUser(updateUserService(data));
  };

  const handleUpdateCompany = (data: UpdateCompanyRequestData) => {
    runUpdateCompany(updateCompanyService(data));
  };

  useMemo(() => {
    if (updateUserResponse?.status === 200) {
      getUser();
      setImage({ show: false, type: "" });
      openToast({
        show: true,
        heading: "Profile update successful!",
        text: "",
        type: "success",
        timeOut: 2000,
      });
    } else if (updateUserError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          updateUserError?.response?.data?.error?.message ??
          "Failed to update your personal profile, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [updateUserResponse, updateUserError]);

  useMemo(() => {
    if (updateCompanyResponse?.status === 200) {
      getUser();
      setImage({ show: false, type: "" });
      openToast({
        show: true,
        heading:
          updateCompanyResponse?.data?.message ?? "Company profile updated!",
        text: "",
        type: "success",
        timeOut: 2000,
      });
    } else if (updateCompanyError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          updateCompanyError?.response?.data?.error?.message ??
          "Failed to update your company profile, please try again later",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [updateCompanyResponse, updateCompanyError]);

  const handleImageUpload = (data) => {
    if (image.type === "avatar") handleUpdateUser({ display_photo: data });
    else handleUpdateCompany({ company_logo: data });
  };

  const showLoader =
    updateUserStatus.isPending ||
    updateCompanyStatus.isPending ||
    loadingCountries;

  return (
    <>
      <Loader loading={showLoader} />
      <Navbar title={"Profile & Settings"} />
      <ImageUpload
        {...image}
        close={() => setImage({ show: false, type: "" })}
        submit={handleImageUpload}
      />
      <ChangePassword show={password} close={() => setPassword(false)} />
      <SettingsUI
        submitPersonal={handleUpdateUser}
        submitBusiness={handleUpdateCompany}
        personalData={{
          firstName,
          lastName,
          role,
          number: {
            code: phone.code,
            value: `${phone.code}${phone.number}`,
            number: phone.number,
          },
          country: { label: country, value: country },
        }}
        companyData={{
          name: company,
          industry: industry,
          frequency: { label: hiringFrequency, value: hiringFrequency },
          employees: { label: noOfEmployees, value: noOfEmployees },
        }}
        logo={companyLogo}
        avatar={avatar}
        handleImageUpload={(type) => setImage({ show: true, type })}
        handleChangePassword={() => setPassword(true)}
        countryOptions={countries}
      />
    </>
  );
};

export { Settings };
