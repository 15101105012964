import {
  SubmitDomainKnowledgeData,
  candidateDomainKnowledgeRequestData,
  candidateDomainKnowledgeService,
  fetchCandidateDomainKnowledgeService,
} from "api";
import { Loader } from "components";
import { AssessmentDomainKnowledgeUI, DynamicInputProps } from "features";
import { handleAssessmentTimer } from "helpers";
import { useToast, useApiRequest } from "hooks";
import { useEffect, useMemo, useState } from "react";

interface AssessmentDomainKnowledgeProps {
  callback: (metric: string) => void;
  autoSubmit: boolean;
}

const AssessmentDomainKnowledge: React.FC<AssessmentDomainKnowledgeProps> = ({
  callback,
  autoSubmit,
}) => {
  // State
  const [startTime, setStartTime] = useState<number>(0);

  // Hooks
  const { openToast } = useToast();

  const candidate = localStorage.getItem("assessmentId");

  // API Request Hooks
  const {
    run: runFetch,
    data: fetchResponse,
    requestStatus: fetchStatus,
    error: fetchError,
  } = useApiRequest({});
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const fetchQuestions = (id) =>
    runFetch(fetchCandidateDomainKnowledgeService(id));

  useEffect(() => {
    if (candidate) fetchQuestions(candidate);
  }, []);

  const inputs = useMemo<DynamicInputProps[]>(() => {
    if (fetchResponse?.status === 200) {
      const data = fetchResponse.data.data;
      const questions: DynamicInputProps[] = [];

      data.question_groups.map((group) =>
        group.questions.map((question, index) => {
          questions.push({
            name: `${group.title}_${index}`,
            label: `${group.title}: ${question.question_content}`,
            type: question.question_type,
            _id: question.id,
            validation: {
              // required: question.required ? "Required" : false,
              maxLength:
                question.word_limit && question.question_type === "open_ended"
                  ? {
                      value: question.word_limit * 5,
                      message: "Exceeded character limit",
                    }
                  : false,
              length:
                question.maximum_selections &&
                question.question_type === "multiple_choice"
                  ? {
                      value: question.maximum_selections,
                      message: "Maximum selection",
                    }
                  : false,
            },
            instruction:
              question.description && question.description !== ""
                ? question.description
                : `${
                    question.maximum_selections &&
                    question.question_type === "multiple_choice"
                      ? `Select up to ${question.maximum_selections}`
                      : ""
                  }`,
            options: question.options.map((option) => ({
              label: option.content,
              value: option.id,
            })),
            image: question.image
              ? {
                  value: question.image,
                  description: question.image_description,
                }
              : undefined,
          });
        })
      );

      return questions;
    } else if (fetchError) {
      openToast({
        show: true,
        heading: "Sorry",
        text:
          fetchError?.response?.data?.message ??
          "Failed to fetch questions, please contact support",
        type: "error",
        timeOut: 5000,
      });
    }

    return [];
  }, [fetchResponse, fetchError]);

  const submit = (data: SubmitDomainKnowledgeData[]) => {
    if (!candidate) return;
    const secondsLeft = parseInt(localStorage.getItem("secondsLeft") ?? "0");

    const submitData: candidateDomainKnowledgeRequestData = {
      id: candidate,
      data: {
        domain_knowledge: data,
        minutes_left: Math.ceil(secondsLeft / 60),
      },
    };

    // Set start time
    setStartTime(new Date().getTime());
    run(candidateDomainKnowledgeService(submitData));
  };

  useMemo(() => {
    if (response?.status === 201) {
      // const data = response.data.data;
      const answered = parseInt(localStorage.getItem("answered") ?? "0");
      localStorage.setItem("answered", `${answered + inputs.length}`);

      handleAssessmentTimer(startTime);

      callback("domain_knowledge");
    } else if (error) {
      handleAssessmentTimer(startTime);
      openToast({
        show: true,
        heading: "Sorry",
        text:
          error?.response?.data?.message ??
          "Failed to submit, please try again or contact support",
        type: "error",
        timeOut: 5000,
      });
    }
  }, [response, error]);

  return (
    <>
      <Loader loading={requestStatus.isPending || fetchStatus.isPending} />
      <AssessmentDomainKnowledgeUI
        autoSubmit={autoSubmit}
        submit={submit}
        inputs={inputs}
      />
    </>
  );
};

export { AssessmentDomainKnowledge };
