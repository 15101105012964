import { fetchCountriesService } from "api";
import { useApiRequest } from "../useApiRequest";
import { useMemo } from "react";
import { OptionType } from "components";

export const useFetchCountries = () => {
  // API Request Hooks
  const { run, data: response, requestStatus, error } = useApiRequest({});

  const fetchCountries = () => run(fetchCountriesService());

  const countries = useMemo<OptionType[]>(() => {
    if (response?.status === 200) {
      const data = response.data.map((item) => ({
        label: item.name.common,
        value: item.name.common,
      }));

      return data.sort((a, b) => a.label.localeCompare(b.label));
    }
    return [];
  }, [response, error]);

  return { fetchCountries, countries, loading: requestStatus.isPending };
};
