import {
  fetchAssessmentInfoService,
  fetchCustomEmailService,
  fetchDomainKnowledgeService,
  fetchExpectationService,
  fetchPersonalityService,
  fetchSoftSkillsService,
  fetchWorkCultureService,
} from "api";
import { initOptionType } from "components";
import {
  CustomizedEmailData,
  CustomQuestionFormData,
  DefaultQuestionFormData,
  ExpectationData,
  PersonalityData,
  SoftSkillData,
  WorkCultureData,
} from "features";
import { useApiRequest } from "hooks";
import { useMemo } from "react";
import {
  EmploymentTypeOptions,
  ExperienceEnvOptions,
  ExperienceLevelOptions,
  ExperienceYearOptions,
  QuestionTypeOptions,
  SalaryCurrencyOptions,
  SalaryTermOptions,
  qualitiesOptions,
  skillOptions,
  talentFactorOptions,
} from "utils/options";

const useFetchAssessment = (id) => {
  // API Request Hooks
  const {
    run: runExpectation,
    data: expectationResponse,
    requestStatus: expectationStatus,
    error: expectationError,
  } = useApiRequest({});
  const {
    run: runCulture,
    data: cultureResponse,
    requestStatus: cultureStatus,
    error: cultureError,
  } = useApiRequest({});
  const {
    run: runPersonality,
    data: personalityResponse,
    requestStatus: personalityStatus,
    error: personalityError,
  } = useApiRequest({});
  const {
    run: runSoftskills,
    data: softskillsResponse,
    requestStatus: softskillsStatus,
    error: softskillsError,
  } = useApiRequest({});
  const {
    run: runDomain,
    data: domainResponse,
    requestStatus: domainStatus,
    error: domainError,
  } = useApiRequest({});
  const {
    run: runInfo,
    data: infoResponse,
    requestStatus: infoStatus,
    error: infoError,
  } = useApiRequest({});
  const {
    run: runCustomEmail,
    data: customEmailResponse,
    requestStatus: customEmailStatus,
    error: customEmailError,
  } = useApiRequest({});

  const getExpectation = () =>
    id && runExpectation(fetchExpectationService(id));
  const getCulture = () => id && runCulture(fetchWorkCultureService(id));
  const getPersonality = () =>
    id && runPersonality(fetchPersonalityService(id));
  const getSoftskills = () => id && runSoftskills(fetchSoftSkillsService(id));
  const getDomain = () => id && runDomain(fetchDomainKnowledgeService(id));
  const getInfo = () => id && runInfo(fetchAssessmentInfoService(id));
  const getCustomEmail = () =>
    id && runCustomEmail(fetchCustomEmailService(id));

  const info = useMemo<
    { title: string; instruction: string } | undefined
  >(() => {
    if (infoResponse?.status === 200) {
      const data = infoResponse.data;
      return {
        title: data.title,
        instruction: data.intro_html,
      };
    } else if (infoError) {
      // toast
    }

    return undefined;
  }, [infoResponse, infoError]);

  const expectations = useMemo<ExpectationData | undefined>(() => {
    if (expectationResponse?.status === 200) {
      const data = expectationResponse.data.data;
      return {
        employmentType:
          EmploymentTypeOptions.find(
            (item) => item.value === data.employment_type
          ) ?? initOptionType,
        experienceLevel:
          ExperienceLevelOptions.find(
            (item) => item.value === data.experience_level
          ) ?? initOptionType,
        experienceEnv:
          ExperienceEnvOptions.find(
            (item) => item.value === data.experience_environment
          ) ?? initOptionType,
        experienceYear:
          ExperienceYearOptions.find(
            (item) => item.value === data.years_of_experience
          ) ?? initOptionType,
        location: data.location ?? "",
        locationRequired: data.location ?? false,
        salary: {
          term:
            SalaryTermOptions.find(
              (item) => item.value === data.salary_range_term
            ) ?? initOptionType,
          currency:
            SalaryCurrencyOptions.find(
              (item) => item.value === data.salary_range_currency
            ) ?? initOptionType,
          min: data.min_salary_range_amount,
          max: data.max_salary_range_amount,
        },
      };
    } else if (expectationError) {
      // toast
    }

    return undefined;
  }, [expectationResponse, expectationError]);

  const culture = useMemo<WorkCultureData | undefined>(() => {
    if (cultureResponse?.status === 200) {
      const data = cultureResponse.data.data;
      return {
        factors: talentFactorOptions.filter(
          (item) =>
            item.value === data.motivator1 ||
            item.value === data.motivator2 ||
            item.value === data.motivator3
        ),
        qualities: qualitiesOptions.filter(
          (item) =>
            item.value === data.quality1 ||
            item.value === data.quality2 ||
            item.value === data.quality3
        ),
        prioritize: data.priority_rating,
        collaborative: data.coordinating_rating,
        innovative: data.visualizing_rating,
        efficient: data.planning_rating,
      };
    } else if (cultureError) {
      // toast
    }

    return undefined;
  }, [cultureResponse, cultureError]);

  const personality = useMemo<PersonalityData | undefined>(() => {
    if (personalityResponse?.status === 200) {
      const data = personalityResponse.data.data;
      return {
        best: {
          set1: data.best1,
          set2: data.best2,
          set3: data.best3,
          set4: data.best4,
        },
        nextBest: {
          set1: data.next_best1,
          set2: data.next_best2,
          set3: data.next_best3,
          set4: data.next_best4,
        },
        manageable: {
          set1: data.least_best1,
          set2: data.least_best2,
          set3: data.least_best3,
          set4: data.least_best4,
        },
      };
    } else if (personalityError) {
      // toast
    }

    return undefined;
  }, [personalityResponse, personalityError]);

  const softskills = useMemo<SoftSkillData | undefined>(() => {
    if (softskillsResponse?.status === 200) {
      const data = softskillsResponse.data.data;
      const skills = skillOptions
        .filter(
          (item) =>
            item.value === data.skill1 ||
            item.value === data.skill2 ||
            item.value === data.skill3 ||
            item.value === data.skill4 ||
            item.value === data.skill5
        )
        .map((item) => ({
          label: item.label,
          value: item.value,
          rating:
            item.value === data.skill1
              ? data.skill1_rating
              : item.value === data.skill2
              ? data.skill2_rating
              : item.value === data.skill3
              ? data.skill3_rating
              : item.value === data.skill4
              ? data.skill3_rating
              : data.skill5_rating,
        }));

      return {
        skills,
      };
    } else if (softskillsError) {
      // toast
    }

    return undefined;
  }, [softskillsResponse, softskillsError]);

  const customQ = useMemo<CustomQuestionFormData | undefined>(() => {
    if (domainResponse?.status === 200) {
      const data = domainResponse.data.data;

      if (!data.use_custom) return undefined;
      return {
        questions: data.question_groups.map((question) => ({
          theme: question.title,
          score: "",
          hours: question.duration_hours,
          minutes: question.duration_minutes,
          seconds: question.duration_seconds,
          questions: question.questions.map((sub) => ({
            question: sub.question_content,
            questionType:
              QuestionTypeOptions.find(
                (item) => item.value === sub.question_type
              ) ?? initOptionType,
            description: sub.description,
            required: sub.required,
            wordLimitRequired: sub.word_limit !== null,
            wordLimit: sub.word_limit ?? "",
            points: sub.points_obtainable > 0 ? sub.points_obtainable : "",
            maxSelection:
              sub.maximum_selections > 0 ? `${sub.maximum_selections}` : "",
            options: sub.options.map((option) => ({
              value: option.content,
              isCorrect: option.correct,
            })),
            image: null,
            imageLink: sub.image,
            imageDescription: sub.image_description,
          })),
        })),
      };
    } else if (domainError) {
      // toast
    }

    return undefined;
  }, [domainResponse, domainError]);

  const defaultQ = useMemo<DefaultQuestionFormData | undefined>(() => {
    if (domainResponse?.status === 200) {
      const data = domainResponse.data.data;
      if (data.use_custom) return undefined;

      return {
        questions: data.question_groups.map((item) => ({
          title: item.title,
          description: item.description,
        })),
      };
    } else if (domainError) {
      // toast
    }

    return undefined;
  }, [domainResponse, domainError]);

  const customEmail = useMemo<CustomizedEmailData | undefined>(() => {
    if (customEmailResponse?.status === 200) {
      const data = customEmailResponse.data.data;

      return {
        subject: data.subject,
        section1: data.body1,
        section2: data.body2,
      };
    } else if (customEmailError) {
      // toast
    }

    return undefined;
  }, [customEmailResponse, customEmailError]);

  return {
    info: {
      data: info,
      fetch: getInfo,
    },
    expectation: {
      data: expectations,
      fetch: getExpectation,
    },
    culture: {
      data: culture,
      fetch: getCulture,
    },
    personality: {
      data: personality,
      fetch: getPersonality,
    },
    softskills: {
      data: softskills,
      fetch: getSoftskills,
    },
    domain: {
      customQ: customQ,
      defaultQ: defaultQ,
      isCustom: customQ !== undefined,
      fetch: getDomain,
    },
    customEmail: { data: customEmail, fetch: getCustomEmail },
    loading:
      expectationStatus.isPending ||
      cultureStatus.isPending ||
      personalityStatus.isPending ||
      softskillsStatus.isPending ||
      domainStatus.isPending ||
      infoStatus.isPending ||
      customEmailStatus.isPending,
  };
};

export { useFetchAssessment };
