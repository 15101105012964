import { Button, Input, Textarea } from "components";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { placeholderLogo } from "assets";
import { findWordLength } from "helpers";

export interface CustomizedEmailData {
  subject: string;
  section1: string;
  section2: string;
}

const initInstruction: CustomizedEmailData = {
  subject: ``,
  section1: "",
  section2: "",
};

interface CustomizedEmailProps {
  back: () => void;
  skip: () => void;
  title: string;
  logo: string;
  company: string;
  submit: (data: CustomizedEmailData) => void;
  initData: CustomizedEmailData | undefined;
}

const schema = yup.object({
  section1: yup
    .string()
    .required("Required")
    .test(
      "word-length",
      "You have exceeded the word limit",
      (instruction) => instruction.trim().split(/[\n\s]+/).length <= 500
    ),
  section2: yup
    .string()
    .required("Required")
    .test(
      "word-length",
      "You have exceeded the word limit",
      (instruction) => instruction.trim().split(/[\n\s]+/).length <= 500
    ),
  subject: yup.string().required("Required"),
});

const CustomizedEmailUI: React.FC<CustomizedEmailProps> = ({
  back,
  submit,
  skip,
  title,
  logo,
  company,
  initData,
}) => {
  const [view, setView] = useState("edit");
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<CustomizedEmailData>({
    resolver: yupResolver(schema),
    defaultValues: initInstruction,
  });

  useEffect(() => {
    initData && reset(initData);
  }, [initData]);

  const onSubmit: SubmitHandler<CustomizedEmailData> = (data) => submit(data);

  const wordLimit = 500;

  return (
    <>
      <section className={styles.heading}>
        <h2 className={styles.ttl}>{title}</h2>
        <p className={styles.txt}>
          Customize the email invitation to send to candidates you invite
          directly.
        </p>
        <Button className={styles.skipBtn} type="outline" onClick={skip}>
          Skip
        </Button>
      </section>
      <section className={styles.tabs}>
        <span
          className={view === "edit" ? styles.active : ""}
          onClick={() => setView("edit")}
          role="button"
        >
          Edit
        </span>
        <span
          className={view === "preview" ? styles.active : ""}
          onClick={() => setView("preview")}
          role="button"
        >
          Preview
        </span>
      </section>
      {view === "edit" ? (
        <section>
          <div className={styles.subject}>
            <p>Subject</p>
            <Input
              label=""
              placeholder="Enter the email subject"
              type="text"
              required
              validatorMessage={errors.subject?.message}
              name="subject"
              register={register}
              value={watch("subject")}
              parentClassName={styles.title}
            />
          </div>
          <p className={styles.name}>Dear &#123;candidate_name&#125;,</p>
          <div style={{ position: "relative" }}>
            <Textarea
              placeholder={"We are excited to welcome you..."}
              name={"section1"}
              validatorMessage={errors.section1?.message}
              register={register}
              required
              parentClassName={styles.section1}
              max={wordLimit}
            />
            <p className={styles.wordLimit}>
              {findWordLength(watch("section1"))}/{wordLimit} words
            </p>
          </div>
          <div className={styles.midSec}>
            <p>
              To proceed with the assessment, please follow the link below to
              access the application
            </p>
            <div>
              <Button type={"fill"} onClick={console.log}>
                Start assessment
              </Button>{" "}
              or alternatively visit this link &#123;insert_link&#125;
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <Textarea
              placeholder={
                "We appreciate your interest in joining our team and look forward to reviewing your application. \n\nBest regards, \nHenry Shaw, \nPeople Partner, \nPrecision"
              }
              name={"section2"}
              validatorMessage={errors.section2?.message}
              register={register}
              required
              parentClassName={styles.section2}
              max={wordLimit}
            />
            <p className={styles.wordLimit}>
              {findWordLength(watch("section2"))} /{wordLimit} words
            </p>
          </div>
        </section>
      ) : (
        <section className={styles.preview}>
          <p className={styles.preview__subject}>
            <b>Subject:</b>
            {watch("subject")}
          </p>
          <div className={styles.preview__company}>
            <img
              src={logo}
              alt="logo"
              className={styles.preview__company__logo}
            />
            <p>{company}</p>
          </div>
          <p className={styles.name}>Dear Candidate's Name,</p>
          <p className={styles.preview__body}>{watch("section1")}</p>
          <div className={styles.midSec}>
            <p>
              To proceed with the assessment, please follow the link below to
              access the application
            </p>
            <div>
              <Button type={"fill"} onClick={console.log}>
                Start assessment
              </Button>{" "}
              <p>
                or alternatively visit this link{" "}
                <span>{window.location.origin}/assessment_id</span>
              </p>
            </div>
          </div>
          <p className={styles.preview__body}>{watch("section2")}</p>
        </section>
      )}

      <div className={styles.btnSec}>
        <Button type="outline" onClick={back}>
          Previous
        </Button>
        <Button type="fill" onClick={handleSubmit(onSubmit)}>
          Next
        </Button>
      </div>
    </>
  );
};

export { CustomizedEmailUI };
